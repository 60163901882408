.layout-container {
  @apply px-3;
}

/* Admin Structure */

.mainWrap {
  @apply ml-auto transition-all w-full h-full;
  width: 100%;
  @include lg {
    width: calc(100% - ($navAdminWidthLg));
    &.full-screen {
      width: calc(100% - $navAdminWidthSm);
    }
  }
}
// @include lg {
//   &.hiddenNavbar {
//     .wrapMain {
//       width: calc(100% - (20px + $navAdminWidthSm));
//     }
//   }
// }
/* End Admin Structure */
