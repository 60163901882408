p {
  padding: 0;
  margin: 0;
}
.form_title {
  margin-left: 0;
}
.form_title .favicon {
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -2px;
  width: 22px;
  height: 22px;
}
.form_title span {
  vertical-align: middle;
}
.rightToLeft .form_title .favicon {
  margin-right: 0;
  margin-left: 5px;
}
.close {
  right: 8px;
  left: auto;
}
.rightToLeft .form_title {
  margin-left: 0;
  margin-right: 0;
}
.rightToLeft .close {
  left: 8px;
  right: auto;
}

.about_form {
  text-align: left;
  vertical-align: middle;
  line-height: 20px;
  width: 415px;
  background: #000000;
  border-radius: 5px;
  padding: 5px;
  color: #ffffff;
  font-size: 12px;
  font-family: Calibri;
  position: absolute;
}
.about_form .form_title {
  font-size: 15px;
  line-height: 25px;
}
.about_form .close {
  top: 7px;
  position: absolute;
  cursor: pointer;
}
.about_form .about_content {
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  width: 381px;
  padding: 17px 17px 10px 17px;
}
.about_form .content2 {
  position: relative;
  height: 105px;
}
.about_form .content1 {
  position: relative;
}
.about_form .content2 .aboutLogo {
  position: absolute;
  width: 90px;
  height: 105px;
  left: 0;
}
.about_form.rightToLeft .content2 .aboutLogo {
  left: auto;
  right: 0;
}

.about_form .item {
  width: 100%;
  position: relative;
  margin: 15px 0;
  min-height: 18px;
  overflow: hidden;
}
.about_form .item .title {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.about_form .content2 .item .content {
  margin: 0;
  width: auto;
}
.about_form .content1 .item .content {
  margin: 0 0 0 60px;
  width: auto;
}
.about_form a {
  text-decoration: underline;
}
.about_form .description {
  margin: 0;
}
.about_form .description .content {
  margin: 0;
}

.about_form .logo {
  width: 90px;
  position: absolute;
  left: 0;
}
.about_form .stage {
  overflow: hidden;
  width: 381px;
  height: 109px;
  position: absolute;
  right: 0;
}
.about_form.rightToLeft .stage {
  right: auto;
  left: 0;
}
.about_form .swiper {
  width: 98%;
  position: absolute;
  left: 0;
  top: 0;
}

.about_form .progress {
  width: 15px;
  height: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
.about_form .progressBar {
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.25);
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  border-radius: 2px;
}

.about_form.rightToLeft {
  text-align: right;
}
.about_form.rightToLeft .item .title {
  position: absolute;
  left: auto;
  top: 0;
  right: 0;
  text-align: right;
}
.about_form.rightToLeft .content2 .item .content {
  margin: 0;
  text-align: right;
}
.about_form.rightToLeft .content1 .item .content {
  margin: 0 60px 0 0;
  text-align: right;
}

.color_panel {
  display: none;
  z-index: 9999;
  width: 202px;
  background-color: #ffffff;
  position: absolute;
}
.color_panel tr {
  position: relative;
}
.color_panel td {
  width: 14px;
  height: 14px;
  position: relative;
}

.colorSelection {
  width: 25px;
  height: 25px;
  cursor: pointer;
  border-width: 2px;
  padding: 0;
}

.bookmark_form {
  position: absolute;
  display: block;
  z-index: 1000;
  left: -280px;
  top: 157px;
  width: 270px;
  height: 470px;
  background: #000000;
  border-radius: 5px;
  padding: 5px;
  color: #ffffff;
  font-size: 14px;
  font-family: Calibri;
}
.bookmark_form .form_title {
  font-size: 15px;
  height: 25px;
}
.bookmark_form .close {
  top: 7px;
  position: absolute;
  cursor: pointer;
}
.bookmark_form .bookmark_content {
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  width: 250px;
  height: 425px;
  padding: 10px;
}

.bookmark_form .inputForm {
  width: 100%;
  height: 25px;
  position: relative;
}
.bookmark_form .input_title {
  width: 200px;
  height: 25px;
  border: none;
  padding: 0 10px;
  position: absolute;
  left: 0;
  color: var(--text-color);
}
.bookmark_form .colorSelection {
  width: 22px;
  height: 23px;
  border: 1px solid #ffffff;
  position: absolute;
  right: 0;
}
.bookmark_form .add_page {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  width: 120px;
  height: 26px;
  line-height: 26px;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.25);
}
.bookmark_form .add_page.left {
  margin-right: 10px;
}
.bookmark_form .list_title {
  cursor: default;
  font-size: 12px;
  height: 26px;
  width: 100%;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.1);
  line-height: 26px;
  vertical-align: middle;
  text-align: left;
}
.bookmark_form .list_title span {
  padding: 0 10px;
  display: inline-block;
}
.bookmark_form .list_title .page {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  width: 30px;
}
.bookmark_form .list_title .description {
  width: 120px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.bookmark_form .list_title .edit {
}

.bookmark_form .stage {
  overflow: hidden;
  width: 100%;
  position: relative;
}
.bookmark_form .swiper {
  width: 100%;
  left: 0;
  top: 0;
}

.bookmark_form .progress {
  width: 15px;
  height: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
.bookmark_form .progressBar {
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.25);
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  border-radius: 2px;
}

.bookmark_form .removeAll {
  margin: 5px 0 0 0;
  width: 100%;
  height: 26px;
  line-height: 26px;
  background: rgba(255, 255, 255, 0.25);
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  display: none;
}

.bookmark_form .item {
  width: 100%;
  height: 26px;
  line-height: 26px;
  vertical-align: middle;
  text-align: left;
  position: relative;
  margin-top: 5px;
}
.bookmark_form .item span {
  padding: 0 10px;
  display: inline-block;
}
.bookmark_form .item input {
  display: inline-block;
}
.bookmark_form .item .page {
  width: 30px;
}
.bookmark_form .item .title {
  width: 100px;
  height: 20px;
  border: none;
  padding: 0 10px;
  background: transparent;
}
.bookmark_form .item .colorSelection {
  margin: 0;
  width: 20px;
  height: 18px;
  position: absolute;
  left: 149px;
  top: 3px;
  display: none;
}
.bookmark_form .item img {
  position: absolute;
  cursor: pointer;
}
.bookmark_form .item .save {
  left: 174px;
  top: 4px;
  display: none;
}
.bookmark_form .item .cancel {
  left: 194px;
  top: 4px;
  display: none;
}
.bookmark_form .item .edit {
  left: 194px;
  top: 4px;
}
.bookmark_form .item .delete {
  left: 218px;
  top: 4px;
}
.bookmark_form .item.bookmarkItem_focus {
  background: rgba(255, 255, 255, 0.25);
}

.bookmark_form.rightToLeft {
  text-align: right;
}
.bookmark_form.rightToLeft .input_title {
  left: auto;
  right: 0;
  direction: rtl;
}
.bookmark_form.rightToLeft .colorSelection {
  right: auto;
  left: 0;
}
.bookmark_form.rightToLeft .list_title {
  text-align: right;
}
.bookmark_form.rightToLeft .list_title .page {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: none;
  width: 30px;
}
.bookmark_form.rightToLeft .list_title .description {
  width: 120px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: none;
}
.bookmark_form.rightToLeft .list_title .edit {
}
.bookmark_form.rightToLeft .progress {
  left: 0;
  right: auto;
}
.bookmark_form.rightToLeft .progressBar {
  left: 0;
  right: auto;
}

.bookmark_form.rightToLeft .item {
  text-align: right;
}
.bookmark_form.rightToLeft .item .title {
  direction: rtl;
}
.bookmark_form.rightToLeft .item .colorSelection {
  right: 149px;
  left: auto;
}
.bookmark_form.rightToLeft .item .save {
  right: 174px;
  left: auto;
}
.bookmark_form.rightToLeft .item .cancel {
  right: 194px;
  left: auto;
}
.bookmark_form.rightToLeft .item .edit {
  right: 194px;
  left: auto;
}
.bookmark_form.rightToLeft .item .delete {
  right: 218px;
  left: auto;
}

.bookmark_form.phone {
  background: transparent;
  width: 100%;
  height: 100%;
  padding: 0;
  left: 0;
  top: 0;
  margin: 0;
}
.bookmark_form.phone .stage {
  margin: 0;
  width: 100%;
  height: 100%;
}
.bookmark_form.phone .item {
  position: relative;
  width: 100%;
  height: 100px;
  line-height: 100px;
  vertical-align: middle;
  font-size: 15px;
  cursor: pointer;
}
.bookmark_form.phone .item span {
  margin: 0 10px;
  float: left;
  display: inline-block;
  width: auto;
}
.bookmark_form.phone .item img {
  height: 76px;
  float: left;
  margin: 12px 0;
  position: relative;
}
.bookmark_form.phone .item .delete {
  position: absolute;
  cursor: pointer;
  top: 30px;
  left: auto;
  right: 10px;
  width: 41px;
  height: 41px;
  background-position: -402px -429px;
  // background-image: url(phoneIcon/phone_icon.png);
  background-repeat: no-repeat;
}
.bookmark_form.phone .item .delete:hover {
  background-position: -462px -429px;
}

/*.bookmark_form .close{transition: all 500ms;
  -moz-transition: all 500ms;
  -webkit-transition: all 500ms;
  -o-transition: all 500ms;}
  
  .bookmark_form .close:hover{
  transform:rotate(360deg);
  -ms-transform:rotate(360deg);
  -moz-transform:rotate(360deg);
  -webkit-transform:rotate(360deg);
  -o-transform:rotate(360deg); }*/

.download_form {
  text-align: left;
  vertical-align: middle;
  line-height: 20px;
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  background: #000000;
  width: 375px;
  padding: 5px;
  color: #ffffff;
  font-size: 15px;
  font-family: Calibri;
  position: absolute;
}
.download_form .content {
  padding: 20px 20px 0 20px;
  margin-left: -20px;
  position: relative;
  width: 100%;
  height: 198px;
}
.download_form .content1 {
  width: 212px;
  height: 180px;
  border-right: 1px solid rgba(252, 251, 249, 0.5);
  position: absolute;
  top: 20px;
  left: 20px;
}
.download_form .content2 {
  /* border: 1px solid rgba(252, 251, 249, 0.5); */
  display: inline-block;
  position: absolute;
  width: 150px;
  height: 196px;
  right: 20px;
  top: 20px;
}
.download_form .download_content {
  background: rgba(255, 255, 255, 0.2);
}
.download_form .form_title {
  line-height: 25px;
}
.download_form .close {
  position: absolute;
  top: 7px;
}
.download_form .downloadButton {
  cursor: pointer;
  width: 181px;
  height: 30px;
  margin: 10px 10px 0 10px;
  background: rgba(255, 255, 255, 0.25);
  line-height: 30px;
  vertical-align: middle;
  text-align: center;
}
.download_form .item {
  cursor: pointer;
  line-height: 30px;
  vertical-align: middle;
  position: relative;
}
.download_form .item span {
  margin-left: 6px;
  vertical-align: middle;
}
.download_form .item img {
  vertical-align: middle;
  margin: 4px;
  margin-left: 10px;
}
.download_form .downloadAttachments input {
  padding: 0 5px;
  width: 102px;
  height: 30px;
  border: none;
  margin-left: 10px;
  font-size: 12px;
}
.download_form .content1 select {
  margin: 10px;
  margin-top: 5px;
  width: 181px;
  color: #989898;
  height: 30px;
  border: none;
  border-radius: 0;
  outline: none;
}
/* .download_form .downloadAll{margin-top:30px;} */

.download_form.rightToLeft {
  text-align: right;
}
.download_form.rightToLeft .item {
  padding: 0;
}
.download_form.rightToLeft .item img {
  left: auto;
  right: 4px;
}
.download_form.rightToLeft .item span {
  margin: 0 10px 0 0;
}
.download_form.rightToLeft .downloadAttachments input {
  margin: 0 10px 0 0;
}
/* .download_form .downloadButton{margin:20px 0 0 0;width:auto;} */

.help_form {
  text-align: left;
  vertical-align: middle;
  line-height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 380px;
  background: #000000;
  border-radius: 5px;
  padding: 5px;
  color: #ffffff;
  font-size: 15px;
  font-family: Calibri;
}
.help_form .form_title {
  font-size: 15px;
  line-height: 25px;
}
.help_form .close {
  top: 7px;
  position: absolute;
  cursor: pointer;
}
.help_form .help_content {
  text-align: center;
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  width: 346px;
  padding: 17px 17px 17px 17px;
}
.help_form .logo {
  text-align: center;
  margin: 10px;
  max-width: 380px;
  max-height: 200px;
}
.help_form .description {
  margin: 10px;
}

.help_form.rightToLeft {
  text-align: right;
}

.pictureList_win10 {
  width: 150px;
  height: 196px;
  position: absolute;
  left: 0;
}
.pictureList_win10 .thumbnail .page {
  width: 100%;
  height: 20px;
  line-height: 20px;
}
.pictureList_win10 .thumbnail img {
  vertical-align: middle;
  width: 100%;
}
.pictureList_win10 .btn {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin: auto;
  z-index: 3;
}
.pictureList_win10 .leftBtn {
  left: 0;
}
.pictureList_win10 .rightBtn {
  right: 0;
}

.pictureList_win10 {
  left: 0;
  text-align: center;
  vertical-align: middle;
}
.pictureList_win10 .thumbnail {
  width: 100%;
  position: relative;
  display: inline-block;
  left: 0;
  top: 0;
  text-align: center;
  vertical-align: middle;
}
.pictureList_win10:after {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.print_form {
  text-align: left;
  vertical-align: middle;
  line-height: 20px;
  position: absolute;
  border-radius: 5px;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 398px;
  background: #000000;
  padding: 5px;
  color: #ffffff;
  font-size: 12px;
  font-family: Calibri;
}
.print_form .form_title {
  font-size: 15px;
  line-height: 25px;
}
.print_form .close {
  top: 7px;
  position: absolute;
  cursor: pointer;
}
.print_form .content {
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  width: 237px;
  padding: 10px 10px 10px 151px;
}

.print_form .printDiv {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  width: 227px;
  padding: 10px;
}
.print_form .printButton {
  cursor: pointer;
  width: 100%;
  height: 26px;
  margin: 20px 0;
  background: rgba(255, 255, 255, 0.25);
  line-height: 26px;
  vertical-align: middle;
  text-align: center;
}
.print_form .item {
  cursor: pointer;
  margin: 10px 0;
  line-height: 26px;
  vertical-align: middle;
  position: relative;
  padding: 0 0 0 20px;
}
.print_form .item span {
  margin-left: 10px;
}
.print_form .item img {
  vertical-align: middle;
  position: absolute;
  left: 4px;
  top: 6px;
}
.print_form .printRange input {
  padding: 0 5px;
  width: 100px;
  height: 26px;
  border: none;
  margin-left: 10px;
  font-size: 12px;
}

.print_form.rightToLeft {
  text-align: right;
}
.print_form.rightToLeft .item {
  padding: 0;
}
.print_form.rightToLeft .item img {
  left: auto;
  right: 4px;
}
.print_form.rightToLeft .item span {
  margin: 0 30px 0 0;
}
.print_form.rightToLeft .printRange input {
  margin: 0 10px 0 0;
}

.print_form.phone {
  background: transparent;
  width: 100%;
  padding: 0;
}
.print_form.phone .item {
  margin: 10px;
}
.print_form .printButton {
  margin: 20px 0;
  width: auto;
}

/*scrollbar*/
::-webkit-scrollbar {
  height: 0;
  width: 0;
}

::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}

.search_form {
  z-index: 1000;
  left: -300px;
  top: 0;
  width: 270px;
  height: 548px;
  background: #000000;
  border-radius: 5px;
  padding: 5px;
  color: #ffffff;
  font-size: 12px;
  font-family: Calibri;
  position: absolute;
}
.search_form .form_title {
  font-size: 15px;
  line-height: 25px;
}
.search_form .close {
  top: 7px;
  position: absolute;
  cursor: pointer;
}
.search_form .search_content {
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
}

.search_form .input_search {
  width: 210px;
  height: 30px;
  border: none;
  padding: 0 30px 0 10px;
  margin: 10px 0 0 10px;
}
.search_form .searchButton {
  position: absolute;
  top: 24px;
  right: 15px;
  cursor: pointer;
  z-index: 10;
  max-width: 22px;
  max-height: 22px;
  transform: translate(0, -50%);
}
.search_form .result {
  padding: 0 10px;
  height: 40px;
  vertical-align: middle;
  line-height: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.search_form .stage {
  overflow: hidden;
  width: 100%;
  height: 577px;
  position: relative;
}
.search_form .swiper {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.search_form .progress {
  width: 15px;
  height: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
.search_form .progressBar {
  width: 5px;
  height: 5px;
  opacity: 0.25;
  background: #ffffff;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  border-radius: 2px;
}

.search_form .item {
  padding: 10px 12px;
  cursor: pointer;
}
.search_form .item .description {
  font-size: 12px;
  margin-top: 3px;
  opacity: 0.6;
}
.search_form .item:hover {
  background: rgba(255, 255, 255, 0.25);
}

.search_form.rightToLeft {
  text-align: right;
}
.search_form.rightToLeft .input_search {
  direction: rtl;
  padding: 0 10px 0 30px;
  margin: 10px 10px 0 0;
}
.search_form.rightToLeft .searchButton {
  left: 15px;
  right: auto;
}
.search_form.rightToLeft .progress {
  left: 0;
  right: auto;
}

.search_form.phone {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  width: 100%;
  height: 100%;
  padding: 0;
  left: 0;
  top: 0;
  background: transparent;
}
.search_form.phone .input_search {
  border-radius: 12px;
  margin: 20px 15px;
  height: 20px;
  line-height: 20px;
  padding: 0 25px 0 5px;
  width: 275px;
  background: transparent;
  border: 1px solid transparent;
}
.search_form.phone .result {
  margin: 0;
  height: 25px;
  line-height: 25px;
  background: rgba(255, 255, 255, 0.05);
  padding: 0 10px;
}
.search_form.phone .stage {
  width: 100%;
  position: relative;
  margin: 0;
}
.search_form.phone .searchButton {
  width: 15px;
  height: 15px;
  right: 25px;
  top: 24px;
}
.search_form.phone .searchButton div {
  width: 100%;
  height: 100%;
}
.search_form.rightToLeft.phone .searchButton {
  left: 25px;
  top: 24px;
  right: auto;
}
.search_form.rightToLeft.phone .input_search {
  padding: 0 5px 0 25px;
}

.phone_scroll_y {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.settingpc_form {
  vertical-align: middle;
  line-height: 20px;
  text-align: center;
  width: 310px;
  height: 140px;
  color: #ffffff;
  font-size: 15px;
  font-family: Calibri;
  position: absolute;
}
.settingpc_form {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
}
.settingpc_form .item {
  width: 140px;
  height: 140px;
  margin: 0 7px;
  background: black;
  display: inline-block;
  float: left;
  position: relative;
  cursor: pointer;
}
.settingpc_form .item img {
  width: 60px;
  height: 60px;
  margin-top: 20px;
}
.settingpc_form .item .title {
  width: 100%;
  text-align: center;
}
.setting_form.phone {
  right: auto;
}

.share_form {
  padding: 5px;
  text-align: left;
  vertical-align: middle;
  line-height: 20px;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 416px;
  background: #000000;
  border-radius: 5px;
  color: #ffffff;
  font-size: 12px;
  font-family: Calibri;
  position: absolute;
}
.share_form .form_title {
  font-size: 15px;
  padding: 0 5px;
  line-height: 25px;
  margin: 0;
}
.share_form .close {
  top: 6px;
  right: 10px;
  position: absolute;
  cursor: pointer;
}
.share_form.rightToLeft .close {
  right: auto;
  left: 10px;
}

.share_form .share_content {
  background-color: rgba(255, 255, 255, 0.2);
  position: relative;
  height: 200px;
  width: 365px;
  padding: 25px 25px;
}

.share_form .swiperContainer {
  font-size: 14px;
  width: 210px;
  height: 150px;
  position: absolute;
  right: 20px;
  padding-left: 20px;
  overflow: hidden;
}
.share_form .shareStage {
  width: 210px;
  height: 120px;
  position: absolute;
  top: 30px;
  overflow: hidden;
  right: 0;
}
.share_form .progress {
  width: 15px;
  height: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
.share_form .progressBar {
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.25);
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  border-radius: 2px;
}
.share_form .shareSwiper {
  width: 210px;
  position: absolute;
  right: 0;
  top: 0;
}

.share_form .codeBox {
  position: absolute;
  width: 110px;
  height: 150px;
  padding: 0 5px;
  text-align: center;
}
.share_form .shareCode {
  position: absolute;
  width: 100px;
  height: 100px;
  border: 5px solid #fff;
}
.share_form .miniProgramCode {
  position: absolute;
  width: 124px;
  height: 124px;
  top: -7px;
  left: -7px;
}
.share_form .codeButton {
  position: absolute;
  width: 110px;
  bottom: 0;
  height: 26px;
  line-height: 26px;
}
.share_form .codeButton > span {
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: rgba(255, 255, 255, 0.25);
  cursor: pointer;
  display: inline-block;
  width: 46%;
  padding: 0 2%;
  height: 26px;
  line-height: 26px;
}
.share_form .codeButton > span.active {
  opacity: 1;
}
.share_form .qrcode {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.share_form .miniProgram {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.share_form .share_title {
  position: absolute;
  width: 210px;
  left: 20px;
  top: -3px;
}
.share_form .link_Box {
  position: absolute;
  width: 365px;
  height: 30px;
  bottom: 30px;
}
.share_form .link_input {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 283px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  border: 1px solid #e3e3e3;
}
.share_form .copy {
  background: rgba(255, 255, 255, 0.25);
  width: 60px;
  height: 32px;
  top: 0;
  right: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  position: absolute;
  line-height: 32px;
  vertical-align: middle;
  text-align: center;
}
.share_form .sharePanel img {
  height: 42px;
  margin: 0 10px 5px 0;
  border: none;
}

.share_form.rightToLeft .swiperContainer {
  right: auto;
  left: 20px;
  padding: 0 20px 0 0;
}
.share_form.rightToLeft .shareStage {
  right: auto;
  left: 0;
}
.share_form.rightToLeft .codeBox {
  right: 25px;
}
.share_form.rightToLeft .share_title {
  left: auto;
  right: 20px;
}
.share_form.rightToLeft .link_input {
  border-radius: 0;
  text-align: right;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.share_form.rightToLeft .copy {
  border-radius: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.share_form.rightToLeft .sharePanel img {
  margin: 0 0 5px 10px;
}
.share_form.rightToLeft .progress {
  right: auto;
  left: 0;
}
.share_form.rightToLeft .progressBar {
  right: auto;
  left: 0;
}

.share_form p {
  line-height: 20px;
}

.share_form .shareCurrentPageBox {
  position: absolute;
  left: 25px;
  bottom: 65px;
}
.share_form .shareCurrentPage {
  position: absolute;
  margin: 4px 0;
  left: 0;
  width: 14px;
  height: 13px;
}
.share_form .shareCurrentPageTitle {
  display: inline-block;
  margin: 0 25px;
}

.share_form.rightToLeft .shareCurrentPageBox {
  left: auto;
  right: 25px;
}
.share_form.rightToLeft .shareCurrentPage {
  position: absolute;
  left: auto;
  right: 0;
}

.share_form.rightToLeft {
  text-align: right;
}
.share_form.rightToLeft .copy {
  left: 0;
  right: auto;
}

.share_form.phone {
  background: transparent;
  width: 100%;
  height: 100%;
  padding: 0;
}
.share_form.phone .stage {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}
.share_form.phone .swiper {
  width: 100%;
  left: 0;
  top: 0;
}
.share_form.phone .progress {
  left: 0;
  right: auto;
}
.share_form.phone .progressBar {
  left: 0;
  right: auto;
}

.share_form.phone .item {
  padding: 10px;
  vertical-align: middle;
  font-size: 15px;
  line-height: 50px;
  height: 50px;
  font-weight: bold;
  position: relative;
}
.share_form.phone .item .logo {
  width: 50px;
  height: 50px;
  display: inline-block;
  float: left;
}
.share_form.phone .item .logo div {
  width: 50px;
  height: 50px;
}
.share_form.phone .item span {
  margin-left: 20px;
  float: left;
}

.tableofcontent_form {
  position: absolute;
  z-index: 1000;
  left: -300px;
  top: 150px;
  width: 270px;
  height: 548px;
  background: #000000;
  border-radius: 5px;
  padding: 5px;
  color: #ffffff;
  font-size: 12px;
  font-family: Calibri;
}
.tableofcontent_form .form_title {
  font-size: 15px;
  line-height: 25px;
}
.tableofcontent_form .close {
  top: 7px;
  position: absolute;
  cursor: pointer;
}
.tableofcontent_form .tableofcontent_content {
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
}

.tableofcontent_form .stage {
  overflow: hidden;
  width: 100%;
  height: 522px;
  position: relative;
}
.tableofcontent_form .swiper {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.tableofcontent_form .progress {
  width: 15px;
  height: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
.tableofcontent_form .progressBar {
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.25);
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  border-radius: 2px;
}

.tableofcontent_form .item {
  padding: 0 12px;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
  line-height: 34px;
}
.tableofcontent_form .item .arrow {
  padding: 5px;
  position: absolute;
  top: 2px;
}
.tableofcontent_form .item .description {
  display: inline-block;
}
.tableofcontent_form .item img {
  vertical-align: middle;
}
.tableofcontent_form .item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.tableofcontent_form.rightToLeft {
  text-align: right;
}
.tableofcontent_form.rightToLeft .progress {
  left: 0;
  right: auto;
}
.tableofcontent_form.rightToLeft .progressBar {
  left: 0;
  right: auto;
}

.tableofcontent_form.phone {
  background: transparent;
  width: 100%;
  height: 100%;
  padding: 0;
  left: 0;
  top: 0;
  margin: 0;
}
.tableofcontent_form.phone .stage {
  margin: 0;
  width: 100%;
  height: 100%;
}
.tableofcontent_form .searchButton {
  position: absolute;
  top: 52px;
  right: 20px;
  cursor: pointer;
  z-index: 10;
}

.thumbBar {
  position: absolute;
  z-index: 100;
}
.outThumbBG {
  position: absolute;
  overflow: hidden;
  /*background-color: #000000;*/
  /*opacity: 0.9;*/
}
.thumbBG {
  position: absolute;
  /*background-color: #000000;*/
  /*opacity: 0.9;*/
}

.thumbBox {
  position: absolute;
}

.thumbItem {
  position: absolute;
}

.numbering {
  position: absolute;
}

.numberingText {
  position: absolute;
}
.numberingText:after {
  top: 100%;
  border: 10px solid transparent;
  content: " ";
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
}
.numberingText:after {
  border-top-color: #eee;
  border-width: 4px;
  left: 50%;
  margin-left: -4px;
  opacity: 0.9;
}

.dock {
  display: block;
  margin: 0px auto;
  bottom: 0px;
  /*width: 100%;*/
  text-align: center;
  position: absolute;
  /*overflow: hidden;*/
  /*background:#ffff00;*/
}

.linea {
  display: inline-block;
  /*vertical-align: bottom;*/
  margin: 0px;
  padding: 0px;
  position: relative;
  /*overflow: visible;*/
}

.linea img {
  -webkit-box-reflect: below 1px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(0.5, transparent), to(rgba(255, 255, 255, 0.3)));
  -moz-box-reflect: below 1px -moz-gradient(linear, left top, left bottom, from(transparent), color-stop(0.5, transparent), to(rgba(255, 255, 255, 0.3)));
}

.fbTopBar {
  position: absolute;
  overflow: hidden;
  z-index: 99;
  background-color: #111111;
  box-shadow: rgb(0, 0, 0) 0px 1px 2px;

  top: 0px;
  left: 0px;
  right: 0px;

  height: 46px;
}

.pageBar {
  position: absolute;
  height: 100%;
  left: 0px;
  right: auto;
  z-index: 3;
}

.pageNumber {
  position: absolute;
}

.pageNumber input {
  border-width: 0px;
  text-align: center;
  vertical-align: middle;
  height: 24px;
  border-radius: 3px;
  font-family: Arial, sans-serif;
  font-size: 15px;
  margin-bottom: 2px;
}

.pageNumber input:focus {
  outline: none;
}

.logoBar {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 0px;
  height: 100%;
  z-index: 1;
}

.searchBar {
  position: absolute;
  background-color: #bbbbbb;
  vertical-align: middle;
  border-radius: 1px;
  right: 10px;
  left: auto;
  top: 8.5px;
  z-index: 2;
  padding: 2px 2px 2px 4px;
}

.searchBar div {
  position: relative;
  border-radius: 3px;
  float: left;
  display: inline;
}

.preSearch {
  height: 18px;
  border-width: 0px;
  margin-left: 5px;
  background-color: #bbbbbb;
  vertical-align: middle;
  font-family: Calibri;
}

.preSearch:focus {
  outline: none;
}

.pageBar .button {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 3px;
}

.pageBar .button img {
  width: 22px;
  height: 22px;
  margin-left: 4px;
  margin-top: 2px;
}

.button span {
  font-family: "Tahoma", "sans-serif", "Arial";
  font-size: 12px;
}

.searchBar .button span,
.pageBar .button span {
  display: none;
}

.searchBar .button {
  position: relative;
  display: inline;
  float: right;
  width: 26px;
  height: 26px;
}

.searchBar .button img {
  width: 20px;
  height: 20px;
}

.extendedBar {
  overflow: hidden;
  position: absolute;
  border-radius: 5px;
  z-index: 100;
}

.extendedBar .button {
  position: absolute;
  width: 150px;
  height: 30px;
  border-radius: 3px;
}

.extendedBar .button img {
  width: 22px;
  height: 22px;
  float: left;
  margin-left: 4px;
  margin-top: 4px;
}

.extendedBar .button span {
  position: absolute;
  margin-left: 12px;
  margin-top: 8px;
}

.extendedBar.rtl .button img {
  width: 22px;
  height: 22px;
  float: right;
  margin-right: 4px;
  margin-top: 4px;
}

.extendedBar.rtl .button span {
  position: absolute;
  right: 0px;
  margin-right: 32px;
  margin-top: 8px;
}

.thumbnail_win10 {
  text-align: center;
  position: absolute;
  z-index: 1000;
  bottom: 10px;
  width: 90%;
  left: 5%;
  height: 116px;
  background: transparent;
  color: #ffffff;
  font-size: 12px;
  font-family: Calibri;
}
.thumbnail_win10 .close {
  top: -24px;
  position: absolute;
  cursor: pointer;
  width: 32px;
  height: 24px;
  background-color: #000000;
  background-repeat: no-repeat;
  background-position: center center;
}

.thumbnail_win10 .stage {
  overflow: hidden;
  height: 80px;
  margin: 0 30px;
  position: relative;
}
.thumbnail_win10 .swiper {
  position: absolute;
  top: 0;
  height: 80px;
}

.thumbnail_win10 .progress {
  display: none;
  width: 0;
  height: 0;
  position: absolute;
  bottom: 7px;
  right: 0;
  cursor: pointer;
}
.thumbnail_win10 .progressBar {
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  border-radius: 4px;
}

.thumbnail_win10 .item_focus {
  border: 2px solid transparent;
  display: inline-block;
  height: 76px;
  cursor: pointer;
  position: absolute;
}
.thumbnail_win10 .title {
  display: none;
  z-index: 10;
  width: 100%;
  height: 78px;
  line-height: 78px;
  background: rgba(0, 0, 0, 0.5);
  vertical-align: middle;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}
.thumbnail_win10 .item {
  height: 78px;
  position: absolute;
  overflow: hidden;
}
.thumbnail_win10 .item img {
  height: 78px;
  pointer-events: none;
}
.thumbnail_win10 .highlight.focus {
  border: 2px solid #016baf;
  overflow: hidden;
}
.thumbnail_win10 .highlight.focus .title {
  border: none;
}
.thumbnail_win10 .item_focus.focus:hover div.title {
  display: block;
}
.thumbnail_win10 .item.focus:hover p.title {
  display: block;
}
.thumbnail_win10 .title {
  pointer-events: none;
}

.thumbnail_win10 .pageCaption {
  cursor: default;
  margin-top: 15px;
  display: inline-block;
  line-height: 20px;
  padding: 0 20px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
}

.thumbnail_win10 .btn {
  position: absolute;
  top: 14px;
  cursor: pointer;
}
.thumbnail_win10 .leftBtn {
  left: 0;
}
.thumbnail_win10 .rightBtn {
  right: 0;
}

.videogallery_form {
  text-align: left;
  vertical-align: middle;
  line-height: 20px;
  position: absolute;
  z-index: 1000;
  color: #ffffff;
  margin: auto;
  width: 570px;
  background: #000000;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
  font-family: Calibri;
  position: absolute;
  overflow: hidden;
}
.videogallery_form .title {
  font-size: 15px;
  line-height: 25px;
}
.videogallery_form .form_title {
  font-size: 15px;
  line-height: 25px;
}
.videogallery_form .close {
  top: 7px;
  position: absolute;
  cursor: pointer;
}
.videogallery_form .videoGallery_content {
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  width: 550px;
  padding: 10px;
}

.videogallery_form .video {
  width: 315px;
  height: 223px;
  background: rgba(255, 255, 255, 0.25);
  margin-top: 0;
}
.videogallery_form .video_list {
  width: 222px;
  height: 223px;
  position: absolute;
  right: 0;
  top: 10px;
}
.videogallery_form .info {
  margin-top: 15px;
}
.videogallery_form .info .description {
  width: 100%;
}
.videogallery_form .description {
  opacity: 0.6;
}

.videogallery_form .stage {
  overflow: hidden;
  width: 100%;
  height: 223px;
  position: relative;
}
.videogallery_form .swiper {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.videogallery_form .progress {
  width: 15px;
  height: 5px;
  position: absolute;
  bottom: 0;
  right: -5px;
  cursor: pointer;
}
.videogallery_form .progressBar {
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.25);
  position: absolute;
  right: 5px;
  top: 0;
  cursor: pointer;
  border-radius: 2px;
}

.videogallery_form .item {
  cursor: pointer;
  width: 156px;
  padding-left: 61px;
  min-height: 42px;
  margin-bottom: 5px;
  position: relative;
}
.videogallery_form .item .title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.videogallery_form .item .thumbnail {
  background-color: rgba(255, 255, 255, 0.25);
  width: 56px;
  height: 42px;
  position: absolute;
  top: 2px;
  left: 0;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.videogallery_form .item:hover {
  text-decoration: underline;
}

.videogallery_form.rightToLeft {
  text-align: right;
}
.videogallery_form.rightToLeft .video_list {
  left: 15px;
  right: auto;
}
.videogallery_form.rightToLeft .item {
  padding-right: 61px;
  padding-left: 0;
  margin-left: 5px;
}
.videogallery_form.rightToLeft .item .thumbnail {
  left: auto;
  right: 0;
}
.videogallery_form.rightToLeft .progress {
  left: -5px;
  right: auto;
}

.appear_win10 {
  position: absolute;
  opacity: 0;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
}
.appear_win10 .main_dom {
  position: relative;
  display: inline-block;
  left: 0;
  top: 0;
}
.appear_win10:after {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.button_hover:hover {
  opacity: 0.7;
  filter: alpha(opacity=40);
}
.button_hover:active {
  opacity: 0.2;
  filter: alpha(opacity=20);
}

.disable {
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.usable {
  opacity: 1;
  filter: alpha(opacity=100);
}
