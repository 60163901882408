@import "../../abstracts/breckpoint";
@import "../../abstracts/variables";

@mixin toggleNavbarOverLay($isShow) {
  @if $isShow {
    background-color: $overlayColor;
    width: 100%;
    pointer-events: auto;
  } @else {
    pointer-events: none;
    width: auto;
    .navbarWrap {
      pointer-events: auto;
    }
  }
}

@media screen and (max-width: calc($lg - 1px)) {
  .showNavbar {
    .adminNav {
      transform: translateX(0);
      @include md {
        @include toggleNavbarOverLay(true);
      }
      .navbarWrap {
        @include md {
          width: 100%;
          width: $navAdminWidthLg;
        }
      }
    }
  }
}

@include lg {
  .hiddenNavbar {
    .adminNav {
      @include toggleNavbarOverLay(false);
      .navbarWrap {
        width: $navAdminWidthSm;
      }
    }
  }
}

.adminNav {
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;

  position: fixed;
  @apply transition-all;

  @include lg {
    width: unset;
    z-index: 2;
  }

  &.open {
    z-index: 20;
    width: 100%;

    .navbarWrap {
      transform: translateX(0);
    }

    @include lg {
      z-index: 10;
      width: auto;
      .navbarWrap {
        width: $navAdminWidthLg;
        .navbarBody .navbarItem .navbarItemWrap {
          padding-inline: 20px;

          h4 {
            display: block;
          }
        }
      }
    }
    .nav-overlay {
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: none;
      @include md {
        display: block;
      }

      @include lg {
        display: none;
      }
    }
  }

  .navbarWrap {
    transform: translateX(-100%);

    @apply relative z-10 h-full overflow-hidden transition-all py-5 bg-white rounded-r-lg;

    width: 100%;

    @include md {
      width: $navAdminWidthLg;
    }

    @include lg {
      width: $navAdminWidthSm;
      transform: translateX(0);
    }

    .navbarHead {
      position: relative;
      display: flex;
      align-items: center;
      gap: 17px;
      margin-bottom: 30px;
      .wrapIcon {
        cursor: pointer;
      }
      .navbarTitle {
        user-select: none;
        span {
          font-weight: 900;
        }
      }
      .navbarCloseButton {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        @include md {
          display: none;
        }
      }
    }
    .navbarBody {
      .navbarItem {
        &:not(:last-child) {
          margin-bottom: 8px;
        }

        .navbarItemWrap {
          cursor: pointer;
          padding: 14px;
          display: flex;
          align-items: center;
          gap: 34px;
          overflow: hidden;
          transition-property: all;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 150ms;

          .wrapIcon {
            flex-shrink: 0;
            display: grid;
            svg {
              margin-inline: auto;
              width: 18px;
              height: 18px;
            }
          }

          h4 {
            white-space: nowrap;
            @include lg {
              overflow: hidden;
            }
          }
        }
      }

      .navbarLink {
        display: block;
        border-radius: 8px;

        &.active {
          background-color: var(--primary-color-light);
          .wrapIcon svg path {
            fill: var(--primary-color);
          }
          h4 {
            color: var(--primary-color);
            font-weight: 600;
          }
        }

        &:hover:not(.active) {
          background-color: var(--primary-color-light);
        }
      }
    }
  }
}
