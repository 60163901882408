.video-play,
.volume-button {
  cursor: pointer;
}
.video-timer {
  cursor: default;
}
/*Start of circle*/
.video-player.circle {
  position: absolute;
}
.video-player.circle .bevel {
  width: 0px;
  height: 0px;
  display: none;
}
.video-circle-controls {
  z-index: 50;
  top: 2px;
  background: rgba(0, 0, 0, 0.2);
  cursor: hand;
}
.video-circle-play {
  width: 22px;
  height: 28px;
  position: absolute;
  margin: auto;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
//   background: url(icon/video-circle.png) no-repeat -10px -39px;
  z-index: 120;
  cursor: hand;
  -moz-transition: all 0.2s ease-in-out;
  /* Firefox */
  -webkit-transition: all 0.2s ease-in-out;
  /* Safari and Chrome */
  -o-transition: all 0.2s ease-in-out;
  /* Opera */
  transition: all 0.2s ease-in-out;
}
.video-paused-button {
//   background: url(icon/video-circle.png) no-repeat -43px -41px;
}
.video-circle-play:hover {
  opacity: 1;
}
/* volume button */
.video-circle-volume-button {
  margin-top: 7px;
  right: 50px;
  position: absolute;
  display: block;
  width: 15px;
  height: 15px;
//   background: url(icon/video-circle.png) no-repeat -6px -6px;
}
.video-circle-volume-mute {
//   background: url(icon/video-circle.png) no-repeat -32px -6px;
}
/*fullscreen*/
.video-circle-player-fullscreen {
  margin-top: 7px;
  right: 15px;
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
//   background: url(icon/video-circle.png) no-repeat -57px -6px;
}
.video-circle-exit-fullscreen {
//   background: url(icon/video-circle.png) no-repeat -79px -6px;
}
/*video seek*/
/* seek */
.video-circle-seek {
  position: absolute;
  width: 70px;
  height: 70px;
  margin: auto;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 50;
}
.video-circle-time {
  position: absolute;
  width: 55px;
  height: 35px;
  margin: auto;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  color: red;
}
.video-circle-range {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 60px;
  height: 60px;
  margin: auto;
  bottom: 0px;
  right: 0px;
  border-radius: 50%;
  border: 2px solid white;
  z-index: 100;
  z-index: 2;
}
/*Start of default*/
.video-player.default {
  position: absolute;
}
.video-player.default .bevel {
  width: 0px;
  height: 0px;
  display: none;
}
.video-player.default .video-controls {
  position: absolute;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  left: 0px;
  bottom: 4px;
  height: 30px;
  background: #222222;
  -moz-transition: all 0.2s ease-in-out;
  /* Firefox */
  -webkit-transition: all 0.2s ease-in-out;
  /* Safari and Chrome */
  -o-transition: all 0.2s ease-in-out;
  /* Opera */
  transition: all 0.2s ease-in-out;
}
.video-player.default .control-border {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 1px;
  height: 1px;
  background: #444444;
}
/*.video-player.default video:hover + .video-controls {
     visibility: visible;
     opacity: 1;
}
 .video-player.default .video-controls:hover {
     visibility: visible;
     opacity: 1;
}
*/
.video-player.default .video-play,
.video-player.default .volume-box,
.video-player.default .volume-button,
.video-player.default .video-timer,
.video-player.default .player-fullscreen {
  float: left;
  margin-top: 4px;
}
.video-player.default .video-play {
  width: 22px;
  height: 22px;
  margin-left: 5px;
  margin-right: 10px;
//   background: url(icon/player-play.png) no-repeat;
  opacity: 0.7;
  cursor: hand;
  -moz-transition: all 0.2s ease-in-out;
  /* Firefox */
  -webkit-transition: all 0.2s ease-in-out;
  /* Safari and Chrome */
  -o-transition: all 0.2s ease-in-out;
  /* Opera */
  transition: all 0.2s ease-in-out;
}
.video-player.default .paused-button {
//   background: url(icon/player-pause.png) no-repeat;
}
.video-player.default .video-play:hover {
  opacity: 1;
}
.video-player.default .video-timer {
  margin-top: 8px;
  margin-right: 10px;
  cursor: default;
  color: #999;
  font-family: Arial, Tahoma;
  font-size: 0.7em;
  font-weight: bold;
}
/* volume button */
.video-player.default .volume-button {
  margin-right: 10px;
  display: block;
  width: 22px;
  height: 22px;
//   background: url(icon/player-volume-full.png) no-repeat;
  cursor: hand;
  opacity: 0.8;
}
.video-player.default .volume-button:hover {
  opacity: 1;
}
.video-player.default .volume-mute {
//   background: url(icon/player-volume.png) no-repeat;
}
/*fullscreen*/
.video-player.default .player-fullscreen {
  margin-top: 6px;
  display: block;
  width: 22px;
  height: 22px;
//   background: url(icon/player-fullscreen.png) no-repeat;
  cursor: hand;
  opacity: 0.8;
}
.video-player.default .player-fullscreen:hover {
  opacity: 1;
}
.video-player.default .player-exit-fullscreen {
  margin-top: 4px;
//   background: url(icon/player-exit-fullscreen.png) no-repeat;
}
/*video seek*/
/* seek */
.video-player.default .video-seek {
  float: left;
  position: relative;
  width: 80px;
  height: 10px;
  margin-top: 10px;
  margin-right: 10px;
}
.video-player.default .video-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  border: 1px solid #494949;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  background: #535353;
  background-image: -moz-linear-gradient(top, #535353, #333333);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #535353),
    color-stop(1, #333333)
  );
  box-shadow: inset 0 -3px 3px #333333;
}
.video-player.default .video-seek .slider-range {
  position: absolute;
  top: 1px;
  left: 0px;
  height: 100%;
  -moz-border-radius: 15px 0px 0px 15px;
  -webkit-border-radius: 15px 0px 0px 15px;
  border-radius: 15px 0px 0px 15px;
  z-index: 2;
  background: #4cbae8;
  background-image: -moz-linear-gradient(top, #4cbae8, #39a2ce);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #4cbae8),
    color-stop(1, #39a2ce)
  );
  box-shadow: inset 0 -3px 3px #39a2ce;
}
.video-player.default .video-seek .slider-handle {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 10px;
  height: 10px;
  border: 1px solid #323232;
  z-index: 3;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #e6e6e6;
  background-image: -moz-linear-gradient(top, #e6e6e6, #d5d5d5);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #e6e6e6),
    color-stop(1, #d5d5d5)
  );
  box-shadow: inset 0 -3px 3px #d5d5d5;
}
.video-player.default .volume-seek {
  position: absolute;
  height: 60px;
  width: 6px;
  bottom: 30px;
  visibility: hidden;
  opacity: 0;
}
.video-player.default .volume-seek:hover {
  visibility: visible;
  opacity: 1;
}
.video-player.default .volume-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  border: 1px solid #444;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #535353;
  background-image: -moz-linear-gradient(top, #535353, #333333);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #535353),
    color-stop(1, #333333)
  );
  box-shadow: inset 0 3px 3px #333333;
}
.video-player.default .volume-seek .slider-range {
  position: absolute;
  bottom: 0px;
  left: 1px;
  width: 100%;
  height: 0;
  -moz-border-radius: 0px 0px 5px 5px;
  -webkit-border-radius: 0px 0px 5px 5px;
  border-radius: 0px 0px 5px 5px;
  z-index: 2;
  background: #e6e6e6;
  background-image: -moz-linear-gradient(top, #e6e6e6, #d5d5d5);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #e6e6e6),
    color-stop(1, #d5d5d5)
  );
  box-shadow: inset 0 -3px 3px #b9a2ce;
}
.video-player.default .volume-seek .slider-handle {
  position: absolute;
  bottom: 0px;
  left: -2px;
  width: 10px;
  height: 10px;
  border: 1px solid #323232;
  z-index: 3;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #e6e6e6;
  background-image: -moz-linear-gradient(top, #e6e6e6, #d5d5d5);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #e6e6e6),
    color-stop(1, #d5d5d5)
  );
  box-shadow: inset 0 -3px 3px #b9a2ce;
}
/*start of round*/
.video-player.round {
  position: absolute;
  -webkit-mask: none;
}
.video-player.round .bevel {
  display: block;
  float: left;
  position: relative;
  height: 80%;
  top: 10%;
  width: 0px;
  margin-right: 10px;
  border-left: 1px solid #000000;
  border-right: 1px solid #555555;
}
.video-player.round .video-controls {
  position: absolute;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  width: 90%;
  left: 5%;
  bottom: 10px;
  height: 30px;
  background: #222222;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  -moz-transition: all 0.2s ease-in-out;
  /* Firefox */
  -webkit-transition: all 0.2s ease-in-out;
  /* Safari and Chrome */
  -o-transition: all 0.2s ease-in-out;
  /* Opera */
  transition: all 0.2s ease-in-out;
}
.video-player.round .control-border {
  display: none;
}
/*.video-player.round video:hover + .video-controls {
     visibility: visible;
     opacity: 0.8;
}
 .video-player.round .video-controls:hover {
     visibility: visible;
     opacity: 0.8;
}
*/
.video-player.round .video-play,
.video-player.round .volume-box,
.video-player.round .volume-button,
.video-player.round .video-timer,
.video-player.round .player-fullscreen {
  float: left;
  margin-top: 4px;
}
.video-player.round .video-play {
  width: 22px;
  height: 22px;
  margin-left: 10px;
  margin-right: 10px;
//   background: url(icon/player-play.png) no-repeat;
  opacity: 0.7;
  cursor: hand;
  -moz-transition: all 0.2s ease-in-out;
  /* Firefox */
  -webkit-transition: all 0.2s ease-in-out;
  /* Safari and Chrome */
  -o-transition: all 0.2s ease-in-out;
  /* Opera */
  transition: all 0.2s ease-in-out;
}
.video-player.round .paused-button {
//   background: url(icon/player-pause.png) no-repeat;
}
.video-player.round .video-play:hover {
  opacity: 1;
}
.video-player.round .video-timer {
  margin-top: 8px;
  margin-right: 10px;
  cursor: default;
  color: #999;
  font-family: Arial, Tahoma;
  font-size: 0.7em;
  font-weight: bold;
}
/* volume button */
.video-player.round .volume-button {
  margin-right: 10px;
  display: block;
  width: 22px;
  height: 22px;
//   background: url(icon/player-volume-full.png) no-repeat;
  cursor: hand;
  opacity: 0.8;
}
.video-player.round .volume-button:hover {
  opacity: 1;
}
.video-player.round .volume-mute {
//   background: url(icon/player-volume.png) no-repeat;
}
/*fullscreen*/
.video-player.round .player-fullscreen {
  display: block;
  margin-right: 10px;
  width: 22px;
  height: 22px;
//   background: url(icon/round-player-fullscreen.png) no-repeat;
  cursor: hand;
  opacity: 0.8;
}
.video-player.round .player-fullscreen:hover {
  opacity: 1;
}
.video-player.round .player-exit-fullscreen {
  margin-top: 4px;
//   background: url(icon/round-player-exit-fullscreen.png) no-repeat;
}
/*video seek*/
/* seek */
.video-player.round .video-seek {
  float: left;
  position: relative;
  width: 80px;
  height: 5px;
  margin-top: 12px;
  margin-right: 15px;
}
.video-player.round .video-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  border: 1px solid #968f89;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  background: #535353;
  background-image: -moz-linear-gradient(top, #c8c8ca, #cecece);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #cecece),
    color-stop(1, #c8c8ca)
  );
  box-shadow: inset 0 -3px 3px #c8c8ca;
}
.video-player.round .video-seek .slider-range {
  position: absolute;
  top: 1px;
  left: 1px;
  height: 100%;
  -moz-border-radius: 15px 0px 0px 15px;
  -webkit-border-radius: 15px 0px 0px 15px;
  border-radius: 15px 0px 0px 15px;
  z-index: 2;
  background: #f68100;
  background-image: -moz-linear-gradient(top, #f68100, #ee840e);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #f68100),
    color-stop(1, #ee840e)
  );
  box-shadow: inset 0 -3px 3px #ee840e;
}
.video-player.round .video-seek .slider-handle {
  position: absolute;
  top: -5px;
  left: 0px;
//   background: url(icon/round-seek-handle.png) no-repeat;
  width: 20px;
  height: 20px;
  z-index: 3;
}
.video-player.round .volume-seek {
  position: absolute;
  height: 60px;
  width: 6px;
  bottom: 25px;
  visibility: hidden;
  opacity: 0;
}
.video-player.round .volume-seek:hover {
  visibility: visible;
  opacity: 1;
}
.video-player.round .volume-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  border: 1px solid #444;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #535353;
  background-image: -moz-linear-gradient(top, #535353, #333333);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #535353),
    color-stop(1, #333333)
  );
  box-shadow: inset 0 3px 3px #333333;
}
.video-player.round .volume-seek .slider-range {
  position: absolute;
  bottom: 0px;
  left: 1px;
  width: 100%;
  height: 0;
  -moz-border-radius: 0px 0px 5px 5px;
  -webkit-border-radius: 0px 0px 5px 5px;
  border-radius: 0px 0px 5px 5px;
  z-index: 2;
  background: #e6e6e6;
  background-image: -moz-linear-gradient(top, #e6e6e6, #d5d5d5);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #e6e6e6),
    color-stop(1, #d5d5d5)
  );
  box-shadow: inset 0 -3px 3px #b9a2ce;
}
.video-player.round .volume-seek .slider-handle {
  position: absolute;
  bottom: 0px;
  left: -2px;
  width: 10px;
  height: 10px;
  border: 1px solid #323232;
  z-index: 3;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #e6e6e6;
  background-image: -moz-linear-gradient(top, #e6e6e6, #d5d5d5);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #e6e6e6),
    color-stop(1, #d5d5d5)
  );
  box-shadow: inset 0 -3px 3px #b9a2ce;
}
/*start of classic*/
.video-player.classic {
  position: absolute;
}
.video-player.classic .bevel {
  display: block;
  float: left;
  position: relative;
  height: 80%;
  top: 10%;
  width: 0px;
  border-left: 1px solid #000000;
  border-right: 1px solid #555555;
}
.video-player.classic .video-controls {
  position: absolute;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  left: 0px;
  bottom: 0px;
  height: 40px;
  background: #222222;
  -moz-transition: all 0.2s ease-in-out;
  /* Firefox */
  -webkit-transition: all 0.2s ease-in-out;
  /* Safari and Chrome */
  -o-transition: all 0.2s ease-in-out;
  /* Opera */
  transition: all 0.2s ease-in-out;
}
.video-player.classic .control-border {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 1px;
  height: 1px;
  background: #444444;
}
/*.video-player.classic video:hover + .video-controls {
     visibility: visible;
     opacity: 1;
}
 .video-player.classic .video-controls:hover {
     visibility: visible;
     opacity: 1;
}
*/
.video-player.classic .video-play,
.video-player.classic .volume-box,
.video-player.classic .volume-button,
.video-player.classic .video-timer,
.video-player.classic .player-fullscreen {
  float: left;
  margin-top: 4px;
}
.video-player.classic .video-play {
  width: 32px;
  height: 32px;
  margin-left: 10px;
  margin-right: 10px;
//   background: url(icon/classic-buttons.png) 0 0 no-repeat;
  opacity: 0.7;
  cursor: hand;
  -moz-transition: all 0.2s ease-in-out;
  /* Firefox */
  -webkit-transition: all 0.2s ease-in-out;
  /* Safari and Chrome */
  -o-transition: all 0.2s ease-in-out;
  /* Opera */
  transition: all 0.2s ease-in-out;
}
.video-player.classic .paused-button {
//   background: url(icon/classic-buttons.png) -33px 0px no-repeat;
}
.video-player.classic .video-play:hover {
  opacity: 1;
}
.video-player.classic .video-timer {
  margin-top: 13px;
  margin-right: 10px;
  cursor: default;
  color: #999;
  font-family: Arial, Tahoma;
  font-size: 0.7em;
  font-weight: bold;
}
/* volume button */
.video-player.classic .volume-button {
  display: block;
  width: 22px;
  height: 22px;
//   background: url(icon/classic-buttons.png) -100px 0 no-repeat;
  cursor: hand;
  opacity: 0.8;
}
.video-player.classic .volume-button:hover {
  opacity: 1;
}
.video-player.classic .volume-mute {
//   background: url(icon/classic-buttons.png) -100px -32px no-repeat;
}
/*fullscreen*/
.video-player.classic .player-fullscreen {
  margin-top: 4px;
  margin-right: 10px;
  display: block;
  width: 22px;
  height: 22px;
//   background: url(icon/classic-buttons.png) -193px 0 no-repeat;
  cursor: hand;
  opacity: 0.8;
}
.video-player.classic .player-fullscreen:hover {
  opacity: 1;
}
.video-player.classic .player-exit-fullscreen {
  margin-top: 4px;
//   background: url(icon/classic-buttons.png) -225px 0 no-repeat;
}
/*video seek*/
/* seek */
.video-player.classic .video-seek {
  float: left;
  position: relative;
  width: 80px;
  height: 8px;
  margin-top: 15px;
  margin-right: 15px;
}
.video-player.classic .video-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  border: 1px solid #494949;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  background: #535353;
  background-image: -moz-linear-gradient(top, #535353, #333333);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #535353),
    color-stop(1, #333333)
  );
  box-shadow: inset 0 -3px 3px #333333;
}
.video-player.classic .video-seek .slider-range {
  position: absolute;
  top: 1px;
  left: 0px;
  height: 100%;
  -moz-border-radius: 15px 0px 0px 15px;
  -webkit-border-radius: 15px 0px 0px 15px;
  border-radius: 15px 0px 0px 15px;
  z-index: 2;
  background: #90c962;
  background-image: -moz-linear-gradient(top, #90c962, #7ab750);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #90c962),
    color-stop(1, #7ab750)
  );
  box-shadow: inset 0 -3px 3px #7ab750;
}
.video-player.classic .video-seek .slider-handle {
  position: absolute;
  top: -5px;
  left: 0px;
  width: 16px;
  height: 20px;
//   background: url(icon/classic-buttons.png) -168px -5px no-repeat;
  z-index: 3;
}
.video-player.classic .volume-seek {
  position: absolute;
  height: 72px;
  width: 13px;
  bottom: 30px;
  visibility: hidden;
  opacity: 0;
//   background: url(icon/classic-buttons.png) 0 -33px no-repeat;
}
.video-player.classic .volume-seek:hover {
  visibility: visible;
  opacity: 1;
}
.video-player.classic .volume-seek .slider-total {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 4px;
  height: 62px;
  z-index: 1;
  background-color: #000000;
}
.video-player.classic .volume-seek .slider-range {
  position: absolute;
  bottom: 6px;
  left: 6px;
  width: 2px;
  height: 0;
  background-color: #2292fe;
  z-index: 2;
}
.video-player.classic .volume-seek .slider-handle {
  position: absolute;
  bottom: 0px;
  left: 1px;
  width: 12px;
  height: 12px;
  z-index: 3;
//   background: url(icon/classic-buttons.png) -138px -11px no-repeat;
}
/*Start of smallblack*/
.video-player.smallblack {
  position: absolute;
}
.video-player.smallblack .bevel {
  width: 0px;
  height: 0px;
  display: none;
}
.video-player.smallblack .video-controls {
  position: absolute;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  left: 0px;
  bottom: 0px;
  height: 24px;
  background: #222222;
  -moz-transition: all 0.2s ease-in-out;
  /* Firefox */
  -webkit-transition: all 0.2s ease-in-out;
  /* Safari and Chrome */
  -o-transition: all 0.2s ease-in-out;
  /* Opera */
  transition: all 0.2s ease-in-out;
}
.video-player.smallblack .control-border {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 1px;
  height: 1px;
  background: #444444;
}
/*.video-player.smallblack video:hover + .video-controls {
     visibility: visible;
     opacity: 1;
}
 .video-player.smallblack .video-controls:hover {
     visibility: visible;
     opacity: 1;
}
*/
.video-player.smallblack .video-play,
.video-player.smallblack .volume-box,
.video-player.smallblack .volume-button,
.video-player.smallblack .video-timer,
.video-player.smallblack .player-fullscreen {
  float: left;
  margin-top: 0px;
}
.video-player.smallblack .video-play {
  width: 24px;
  height: 24px;
  margin-left: 5px;
  margin-right: 5px;
//   background: url(icon/smallblack-buttons.png) no-repeat;
  opacity: 0.7;
  cursor: hand;
}
.video-player.smallblack .paused-button {
//   background: url(icon/smallblack-buttons.png) -24px 0 no-repeat;
}
.video-player.smallblack .video-play:hover {
  opacity: 1;
}
.video-player.smallblack .video-timer {
  margin-top: 5px;
  margin-right: 10px;
  cursor: default;
  color: #aaa;
  font-family: Arial, Tahoma;
  font-size: 0.6em;
  font-weight: bold;
}
/* volume button */
.video-player.smallblack .volume-button {
  margin-right: 0;
  display: block;
  width: 24px;
  height: 24px;
//   background: url(icon/smallblack-buttons.png) -48px 0 no-repeat;
  cursor: hand;
  opacity: 0.8;
}
.video-player.smallblack .volume-button:hover {
  opacity: 1;
}
.video-player.smallblack .volume-mute {
//   background: url(icon/smallblack-buttons.png) -72px 0 no-repeat;
}
/*fullscreen*/
.video-player.smallblack .player-fullscreen {
  margin-right: 10px;
  display: block;
  width: 24px;
  height: 24px;
//   background: url(icon/smallblack-buttons.png) -96px 0 no-repeat;
  cursor: hand;
  opacity: 0.8;
}
.video-player.smallblack .player-fullscreen:hover {
  opacity: 1;
}
.video-player.smallblack .player-exit-fullscreen {
//   background: url(icon/smallblack-buttons.png) -120px 0 no-repeat;
}
/*video seek*/
/* seek */
.video-player.smallblack .video-seek {
  float: left;
  position: relative;
  width: 80px;
  height: 5px;
  margin-top: 8px;
  margin-right: 10px;
}
.video-player.smallblack .video-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  border: 1px solid #494949;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #535353;
  background-image: -moz-linear-gradient(top, #535353, #333333);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #535353),
    color-stop(1, #333333)
  );
  box-shadow: inset 0 -3px 3px #333333;
}
.video-player.smallblack .video-seek .slider-range {
  position: absolute;
  top: 1px;
  left: 0px;
  height: 100%;
  -moz-border-radius: 5px 0px 0px 5px;
  -webkit-border-radius: 5px 0px 0px 5px;
  border-radius: 5px 0px 0px 5px;
  z-index: 2;
  background: #ececec;
  background-image: -moz-linear-gradient(top, #ececec, #dddddd);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #ececec),
    color-stop(1, #dddddd)
  );
  box-shadow: inset 0 -3px 3px #dddddd;
}
.video-player.smallblack .video-seek .slider-handle {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 6px;
  height: 6px;
  border: 1px solid #323232;
  z-index: 3;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #323232;
  background-image: -moz-linear-gradient(top, #323232, #222222);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #e6e6e6),
    color-stop(1, #222222)
  );
  box-shadow: inset 0 -3px 3px #222222;
}
.video-player.smallblack .volume-seek {
  position: absolute;
  height: 70px;
  width: 12px;
  bottom: 20px;
  visibility: hidden;
  opacity: 0;
  border: 1px solid #000;
  background: #222222;
}
.video-player.smallblack .volume-seek:hover {
  visibility: visible;
  opacity: 1;
}
.video-player.smallblack .volume-seek .slider-total {
  position: absolute;
  top: 3px;
  left: 4px;
  width: 2px;
  height: 62px;
  z-index: 1;
  border: 1px solid #444;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: #535353;
  background-image: -moz-linear-gradient(top, #535353, #333333);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #535353),
    color-stop(1, #333333)
  );
  box-shadow: inset 0 3px 3px #333333;
}
.video-player.smallblack .volume-seek .slider-range {
  position: absolute;
  bottom: 4px;
  left: 5px;
  width: 2px;
  height: 60px;
  -moz-border-radius: 0px 0px 2px 2px;
  -webkit-border-radius: 0px 0px 2px 2px;
  border-radius: 0px 0px 2px 2px;
  z-index: 2;
  background: #e6e6e6;
  background-image: -moz-linear-gradient(top, #e6e6e6, #d5d5d5);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #e6e6e6),
    color-stop(1, #d5d5d5)
  );
  box-shadow: inset 0 -3px 3px #b9a2ce;
}
.video-player.smallblack .volume-seek .slider-handle {
  position: absolute;
  bottom: 0px;
  left: 1px;
  width: 8px;
  height: 8px;
  border: 1px solid #323232;
  z-index: 3;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background: #e6e6e6;
  background-image: -moz-linear-gradient(top, #e6e6e6, #d5d5d5);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #e6e6e6),
    color-stop(1, #d5d5d5)
  );
  box-shadow: inset 0 -3px 3px #b9a2ce;
}
.audio-file,
.audio-timer {
  cursor: default;
}
.video-center-button {
  position: absolute;
  z-index: 50;
  width: 56px;
  height: 56px;
  margin: auto;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  cursor: pointer;
  opacity: 0.8;
//   background: url(icon/play.png) center center no-repeat;
  background-size: contain;
  border: 0px solid #ffd666;
}
.video-center-button:hover {
  opacity: 1;
}
.audio-player {
}
/* start of skin1 */
.audio-player.skin1 {
  position: relative;
  background: #7b796f;
}
.audio-player.skin1 .back_button {
  display: none;
}
.audio-player.skin1 .audio-play {
  position: absolute;
  cursor: pointer;
//   background: url(icon/audio-skin.png) no-repeat -15px -16px;
  width: 10px;
  height: 10px;
  left: 11px;
  top: 0px;
  bottom: 0px;
  z-index: 20;
  margin: auto;
}
.audio-player.skin1 .paused-button {
  position: absolute;
  cursor: pointer;
//   background: url(icon/audio-skin.png) no-repeat -31px -17px;
  width: 10px;
  height: 10px;
  left: 11px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  z-index: 20;
}
.audio-player.skin1 .forward_button {
  display: none;
}
.audio-player.skin1 .volume-button {
  display: none;
}
.audio-player.skin1 .volume-mute {
  display: none;
}
.audio-player.skin1 .audio-timer {
  display: none;
}
.audio-player.skin1 .audio-file {
  display: none;
}
/* seek */
.audio-player.skin1 .audio-seek {
  position: absolute;
  width: 120px;
  height: 10px;
  left: 35px;
  bottom: 0px;
  top: 0px;
  margin: auto;
}
.audio-player.skin1 .audio-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: -webkit-linear-gradient(top, #616159, #b7b4a5);
  border-radius: 4px;
}
.audio-player.skin1 .audio-seek .slider-range {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  z-index: 2;
  box-shadow: inset 0 -3px 3px #acacac;
}
.audio-player.skin1 .audio-seek .slider-handle {
  position: absolute;
  width: 11px;
  height: 11px;
  z-index: 100;
//   background: url(icon/audio-skin.png) no-repeat -46px -17px;
}
.audio-player.skin1 .volume-seek {
  position: absolute;
  width: 79px;
  height: 90%;
  top: 0px;
  bottom: 0px;
  margin: auto;
  right: 8px;
}
.audio-player.skin1 .volume-seek .slider-total {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 90%;
  z-index: 1;
//   background: url(icon/audio-skin.png) no-repeat -150px -7px;
}
.audio-player.skin1 .volume-seek .slider-range {
  position: absolute;
  left: 0px;
  width: 100%;
  top: 0px;
  height: 90%;
  z-index: 2;
//   background: url(icon/audio-skin.png) no-repeat -68px -7px;
}
.audio-player.skin1 .volume-seek .slider-handle {
  display: none;
}
/* start of skin2 */
.audio-player.skin2 {
  position: relative;
  background: #7b796f;
}
.audio-player.skin2 .back_button {
  display: none;
}
.audio-player.skin2 .audio-play {
  position: absolute;
  cursor: pointer;
//   background: url(icon/audio-skin.png) no-repeat -15px -62px;
  width: 10px;
  height: 17px;
  left: 11px;
  top: 0px;
  bottom: 0px;
  z-index: 20;
  margin: auto;
}
.audio-player.skin2 .paused-button {
  position: absolute;
  cursor: pointer;
//   background: url(icon/audio-skin.png) no-repeat -33px -65px;
  width: 15px;
  height: 10px;
  left: 11px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  z-index: 20;
}
.audio-player.skin2 .forward_button {
  display: none;
}
.audio-player.skin2 .volume-button {
  display: none;
}
.audio-player.skin2 .volume-mute {
  display: none;
}
.audio-player.skin2 .audio-timer {
  display: none;
}
.audio-player.skin2 .audio-file {
  display: none;
}
/* seek */
.audio-player.skin2 .audio-seek {
  position: absolute;
  width: 120px;
  height: 10px;
  left: 35px;
  bottom: 0px;
  top: 0px;
  margin: auto;
}
.audio-player.skin2 .audio-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 10px;
  background-image: -webkit-linear-gradient(top, #616159, #b7b4a5);
}
.audio-player.skin2 .audio-seek .slider-range {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 10px;
  z-index: 2;
  background-image: -webkit-linear-gradient(top, #ffffff, #b8fb7d);
}
.audio-player.skin2 .audio-seek .slider-handle {
  display: none;
}
.audio-player.skin2 .volume-seek {
  position: absolute;
  width: 31px;
  height: 26px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  right: 8px;
}
.audio-player.skin2 .volume-seek .slider-total {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 31px;
  height: 26px;
  z-index: 1;
//   background: url(icon/audio-skin.png) no-repeat -95px -58px;
}
.audio-player.skin2 .volume-seek .slider-range {
  position: absolute;
  left: 0px;
  width: 31px;
  top: 0px;
  height: 26px;
  z-index: 2;
//   background: url(icon/audio-skin.png) no-repeat -60px -58px;
}
.audio-player.skin2 .volume-seek .slider-handle {
  display: none;
}
/*Start of default*/
.audio-player.default {
  position: relative;
  background: #deddd8;
  background-image: -moz-linear-gradient(top, #deddd8, #a19f95);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #deddd8),
    color-stop(1, #a19f95)
  );
  box-shadow: 3px 3px 5px #b9a2ce;
  min-height: 55px;
}
.audio-player.default .back_button {
  display: none;
}
.audio-player.default .audio-play {
  position: absolute;
  cursor: pointer;
  width: 36px;
  height: 21px;
  left: 11px;
  bottom: 11px;
//   background: url(icon/audio-default.png) -34px -29px no-repeat;
}
.audio-player.default .paused-button {
//   background: url(icon/audio-default.png) -80px -29px no-repeat;
}
.audio-player.default .forward_button {
  display: none;
}
.audio-player.default .volume-button {
  position: absolute;
  width: 20px;
  height: 15px;
  right: 100px;
  bottom: 11px;
//   background: url(icon/audio-default.png) -133px -34px no-repeat;
}
.audio-player.default .volume-mute {
//   background: url(icon/audio-default.png) -158px -34px no-repeat;
}
.audio-player.default .audio-timer {
  display: none;
}
.audio-player.default .audio-file {
  display: none;
}
/* seek */
.audio-player.default .audio-seek {
  position: absolute;
  width: 80px;
  height: 10px;
  left: 11px;
  bottom: 40px;
}
.audio-player.default .audio-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #6d9cb7;
}
.audio-player.default .audio-seek .slider-range {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  z-index: 2;
  background: #c9c9c8;
  background-image: -moz-linear-gradient(top, #e6e6e4, #acacac);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #e6e6e4),
    color-stop(1, #acacac)
  );
  box-shadow: inset 0 -3px 3px #acacac;
}
.audio-player.default .audio-seek .slider-handle {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 15px;
  height: 10px;
  z-index: 3;
  background: #a69f9a;
  background-image: -moz-linear-gradient(top, #ded2d2, #6f6b63);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #ded2d2),
    color-stop(1, #6f6b63)
  );
  box-shadow: inset 0 -3px 3px #6f6b63;
}
.audio-player.default .volume-seek {
  position: absolute;
  width: 80px;
  height: 10px;
  right: 11px;
  bottom: 15px;
}
.audio-player.default .volume-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-top: 1px solid #aaa9a0;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: #999999;
}
.audio-player.default .volume-seek .slider-range {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  -moz-border-radius: 2px 0px 0px 2px;
  -webkit-border-radius: 2px 0px 0px 2px;
  border-radius: 2px 0px 0px 5px;
  z-index: 2;
  border: 1px solid #a3a2a2;
  background: #cececc;
  background-image: -moz-linear-gradient(top, #e1e1df, #b1b1b0);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #e1e1df),
    color-stop(1, #b1b1b0)
  );
  box-shadow: inset 0 -3px 3px #b1b1b0;
}
.audio-player.default .volume-seek .slider-handle {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 6px;
  height: 12px;
  z-index: 3;
  background: #aaa29e;
  background-image: -moz-linear-gradient(top, #ded2d2, #76726a);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #ded2d2),
    color-stop(1, #76726a)
  );
}
/*Start of decent*/
.audio-player.decent {
  width: 308px;
  height: 114px;
//   background: url(icon/audio-decent.png) 0 0 no-repeat;
}
.audio-player.decent .back_button {
  position: absolute;
  cursor: pointer;
  width: 42px;
  height: 42px;
  left: 115px;
  top: 30px;
//   background: url(icon/audio-decent.png) 0 -133px no-repeat;
}
.audio-player.decent .audio-play {
  position: absolute;
  cursor: pointer;
  width: 54px;
  height: 54px;
  left: 160px;
  top: 20px;
//   background: url(icon/audio-decent.png) -48px -133px no-repeat;
}
.audio-player.decent .paused-button {
//   background: url(icon/audio-decent.png) -105px -133px no-repeat;
}
.audio-player.decent .forward_button {
  position: absolute;
  cursor: pointer;
  width: 42px;
  height: 42px;
  left: 220px;
  top: 30px;
//   background: url(icon/audio-decent.png) -168px -134px no-repeat;
}
.audio-player.decent .volume-button {
  position: absolute;
  width: 21px;
  height: 18px;
  left: 276px;
  top: 84px;
//   background: url(icon/audio-decent.png) -222px -134px no-repeat;
}
.audio-player.decent .volume-mute {
//   background: url(icon/audio-decent.png) -222px -158px no-repeat;
}
.audio-player.decent .audio-timer {
  display: none;
}
.audio-player.decent .audio-file {
  display: none;
}
/* seek */
.audio-player.decent .audio-seek {
  position: absolute;
  width: 160px;
  height: 10px;
  left: 107px;
  top: 88px;
}
.audio-player.decent .audio-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-top: 1px solid #919191;
  border-bottom: 1px solid #f1f1f1;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #c3c3c3;
  box-shadow: inset 0 -3px 3px #c3c3c3;
}
.audio-player.decent .audio-seek .slider-range {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  -moz-border-radius: 5px 0px 0px 5px;
  -webkit-border-radius: 5px 0px 0px 5px;
  border-radius: 5px 0px 0px 5px;
  z-index: 2;
  border: 1px solid #a3a2a2;
  background: #c3f4fc;
  background-image: -moz-linear-gradient(top, #c3f4fc, #a8d3e7);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #c3f4fc),
    color-stop(1, #a8d3e7)
  );
  box-shadow: inset 0 -3px 3px #a8d3e7;
}
.audio-player.decent .audio-seek .slider-handle {
  position: absolute;
  left: 0px;
  top: -3px;
  width: 18px;
  height: 18px;
  z-index: 3;
//   background: url(icon/audio-decent.png) -337px -83px no-repeat;
}
.audio-player.decent .volume-seek {
  position: absolute;
  left: 280px;
  top: 15px;
  height: 60px;
  width: 6px;
  bottom: 30px;
}
.audio-player.decent .volume-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  border: 1px solid #979797;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #535353;
  background-image: -moz-linear-gradient(top, #535353, #333333);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #535353),
    color-stop(1, #333333)
  );
  box-shadow: inset 0 3px 3px #333333;
}
.audio-player.decent .volume-seek .slider-range {
  position: absolute;
  bottom: 0px;
  left: 1px;
  width: 100%;
  height: 0;
  -moz-border-radius: 0px 0px 5px 5px;
  -webkit-border-radius: 0px 0px 5px 5px;
  border-radius: 0px 0px 5px 5px;
  z-index: 2;
  background: #fff6d5;
  background-image: -moz-linear-gradient(top, #fff6d5, #d7f695);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #fff6d5),
    color-stop(1, #d7f695)
  );
  box-shadow: inset 0 -3px 3px #fff6d5;
}
.audio-player.decent .volume-seek .slider-handle {
  position: absolute;
  bottom: 0px;
  left: -4px;
  width: 18px;
  height: 18px;
  z-index: 3;
//   background: url(icon/audio-decent.png) -337px -83px no-repeat;
}
/*Start of advanced*/
.audio-player.advanced {
  width: 398px;
  height: 195px;
//   background: url(icon/audio-advanced.png) -17px -6px no-repeat;
}
.audio-player.advanced .back_button {
  position: absolute;
  cursor: pointer;
  width: 11px;
  height: 10px;
  left: 15px;
  top: 165px;
//   background: url(icon/audio-advanced.png) -33px -212px no-repeat;
}
.audio-player.advanced .audio-play {
  position: absolute;
  cursor: pointer;
  width: 7px;
  height: 10px;
  left: 50px;
  top: 165px;
//   background: url(icon/audio-advanced.png) -70px -211px no-repeat;
}
.audio-player.advanced .paused-button {
//   background: url(icon/audio-advanced.png) -98px -212px no-repeat;
}
.audio-player.advanced .forward_button {
  position: absolute;
  cursor: pointer;
  width: 11px;
  height: 10px;
  left: 78px;
  top: 165px;
//   background: url(icon/audio-advanced.png) -128px -211px no-repeat;
}
.audio-player.advanced .volume-button {
  display: none;
}
.audio-player.advanced .volume-mute {
  display: none;
}
.audio-player.advanced .audio-timer {
  position: absolute;
  right: 10px;
  top: 40px;
  color: #999;
  font-family: Arial, Tahoma;
  font-size: 0.6em;
  font-weight: bold;
}
.audio-player.advanced .audio-file {
  position: absolute;
  right: 10px;
  top: 65px;
  color: #999;
  font-family: Arial, Tahoma;
  font-size: 0.6em;
  font-weight: bold;
}
/* seek */
.audio-player.advanced .audio-seek {
  position: absolute;
  width: 250px;
  height: 2px;
  left: 10px;
  top: 110px;
}
.audio-player.advanced .audio-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #5888a5;
}
.audio-player.advanced .audio-seek .slider-range {
  position: absolute;
  top: -1px;
  left: 0px;
  height: 100%;
  z-index: 2;
  border: 1px solid #5888a5;
  background: #6cacd5;
}
.audio-player.advanced .audio-seek .slider-handle {
  display: none;
}
.audio-player.advanced .volume-seek {
  display: none;
}
.audio-player.advanced .volume-seek .slider-total {
  display: none;
}
.audio-player.advanced .volume-seek .slider-range {
  display: none;
}
.audio-player.advanced .volume-seek .slider-handle {
  display: none;
}
/*list*/
.audio-player.advanced .list-button {
  position: absolute;
  cursor: pointer;
  width: 12px;
  height: 10px;
  left: 235px;
  top: 165px;
//   background: url(icon/audio-advanced.png) -153px -210px no-repeat;
}
.audio-player.advanced .list-panel-bk {
  position: absolute;
  background-color: #333333;
  width: 378px;
  height: 120px;
  left: 0px;
  top: 196px;
  border-radius: 10px;
  padding: 10px;
  display: none;
}
.audio-player.advanced .list-panel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.audio-player.advanced .list-item {
  position: relative;
  float: bottom;
  white-space: nowrap;
  cursor: pointer;
  width: 350px;
  height: 28px;
  line-height: 28px;
  text-align: left;
  vertical-align: middle;
  font-family: "Tahoma", "sans-serif", "Arial";
  font-size: 12px;
  color: #eeeeee;
}
.audio-player.advanced .list-item.selected {
  color: red;
}
.audio-player.advanced .list-item:hover {
  color: red;
}
/*Start of wave*/
.audio-player.wave {
  background: #000;
}
.audio-player.wave .back_button {
  position: absolute;
  cursor: pointer;
  width: 12px;
  height: 11px;
  right: 20px;
  bottom: 5px;
//   background: url(icon/audio-default.png) -245px -34px no-repeat;
}
.audio-player.wave .audio-play {
  position: absolute;
  cursor: pointer;
  width: 7px;
  height: 11px;
  left: 12px;
  bottom: 4px;
//   background: url(icon/audio-default.png) -211px -34px no-repeat;
}
.audio-player.wave .paused-button {
  width: 9px;
//   background: url(icon/audio-default.png) -226px -35px no-repeat;
}
.audio-player.wave .forward_button {
  position: absolute;
  cursor: pointer;
  width: 12px;
  height: 11px;
  right: 2px;
  bottom: 5px;
//   background: url(icon/audio-default.png) -269px -34px no-repeat;
}
.audio-player.wave .volume-button {
  position: absolute;
  cursor: pointer;
  width: 12px;
  height: 12px;
  right: 40px;
  bottom: 4px;
//   background: url(icon/audio-default.png) -289px -33px no-repeat;
}
.audio-player.wave .volume-mute {
//   background: url(icon/audio-default.png) -308px -33px no-repeat;
}
.audio-player.wave .audio-timer {
  position: absolute;
  left: 10px;
  top: 5px;
  color: #2c8db5;
  font-family: Arial, Tahoma;
  font-size: 0.6em;
  font-weight: bold;
}
.audio-player.wave .audio-file {
  position: absolute;
  right: 10px;
  top: 5px;
  color: #2c8db5;
  font-family: Arial, Tahoma;
  font-size: 0.6em;
  font-weight: bold;
}
/* seek */
.audio-player.wave .audio-seek {
  position: absolute;
  width: 150px;
  height: 2px;
  left: 32px;
  bottom: 9px;
}
.audio-player.wave .audio-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #5888a5;
}
.audio-player.wave .audio-seek .slider-range {
  position: absolute;
  top: -1px;
  left: 0px;
  height: 100%;
  z-index: 2;
  border: 1px solid #5888a5;
  background: #6cacd5;
}
.audio-player.wave .audio-seek .slider-handle {
  display: none;
}
.audio-player.wave .volume-seek {
  position: absolute;
  width: 30px;
  height: 2px;
  right: 55px;
  bottom: 9px;
}
.audio-player.wave .volume-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #5888a5;
}
.audio-player.wave .volume-seek .slider-range {
  position: absolute;
  top: -1px;
  left: 0px;
  height: 100%;
  z-index: 2;
  border: 1px solid #5888a5;
  background: #6cacd5;
}
.audio-player.wave .volume-seek .slider-handle {
  display: none;
}
/*list*/
.audio-player.wave .list-button {
  display: none;
}
.audio-player.wave .list-panel-bk {
  position: absolute;
  width: 33%;
  background-color: #000;
  height: 80%;
  right: 0px;
  top: 22px;
  overflow: hidden;
}
.audio-player.wave .list-panel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.audio-player.wave .list-item {
  position: relative;
  float: bottom;
  white-space: nowrap;
  cursor: pointer;
  width: 100%;
  height: 22px;
  line-height: 22px;
  text-align: left;
  vertical-align: middle;
  font-family: "Tahoma", "sans-serif", "Arial";
  font-size: 12px;
  color: #5888a5;
}
.audio-player.wave .list-item.selected {
  color: #80b0c6;
}
.audio-player.wave .list-item:hover {
  color: #80b0c6;
}
/*Start of glass*/
.audio-player.glass {
  width: 405px;
  height: 140px;
//   background: url(icon/audio-glass.png) -11px -13px no-repeat;
}
.audio-player.glass .back_button {
  position: absolute;
  cursor: pointer;
  width: 11px;
  height: 12px;
  left: 14px;
  top: 62px;
//   background: url(icon/audio-glass.png) -251px -316px no-repeat;
}
.audio-player.glass .audio-play {
  position: absolute;
  cursor: pointer;
  width: 50px;
  height: 50px;
  left: 44px;
  top: 44px;
//   background: url(icon/audio-glass.png) -42px -294px no-repeat;
}
.audio-player.glass .paused-button {
//   background: url(icon/audio-glass.png) -104px -294px no-repeat;
}
.audio-player.glass .forward_button {
  position: absolute;
  cursor: pointer;
  width: 11px;
  height: 12px;
  left: 110px;
  top: 62px;
//   background: url(icon/audio-glass.png) -279px -316px no-repeat;
}
.audio-player.glass .volume-up-button {
  position: absolute;
  cursor: pointer;
  width: 11px;
  height: 12px;
  left: 62px;
  top: 16px;
//   background: url(icon/audio-glass.png) -190px -316px no-repeat;
}
.audio-player.glass .volume-down-button {
  position: absolute;
  cursor: pointer;
  width: 11px;
  height: 12px;
  left: 62px;
  top: 110px;
//   background: url(icon/audio-glass.png) -220px -316px no-repeat;
}
.audio-player.glass .volume-button {
  display: none;
}
.audio-player.glass .volume-mute {
  display: none;
}
.audio-player.glass .audio-timer {
  position: absolute;
  left: 275px;
  bottom: 20px;
  color: #ccc;
  font-family: Arial, Tahoma;
  font-size: 0.6em;
  font-weight: bold;
}
.audio-player.glass .audio-file {
  position: absolute;
  left: 150px;
  top: 20px;
  color: #ccc;
  font-family: Arial, Tahoma;
  font-size: 0.6em;
  font-weight: bold;
}
/* seek */
.audio-player.glass .audio-seek {
  position: absolute;
  width: 207px;
  height: 6px;
  left: 146px;
  top: 95px;
}
.audio-player.glass .audio-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
//   background: url(icon/audio-glass.png) -44px -369px no-repeat;
}
.audio-player.glass .audio-seek .slider-range {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  z-index: 2;
//   background: url(icon/audio-glass.png) -44px -381px no-repeat;
}
.audio-player.glass .audio-seek .slider-handle {
  display: none;
}
.audio-player.glass .volume-seek {
  display: none;
}
.audio-player.glass .volume-seek .slider-total {
  display: none;
}
.audio-player.glass .volume-seek .slider-range {
  display: none;
}
.audio-player.glass .volume-seek .slider-handle {
  display: none;
}
/*list*/
.audio-player.glass .list-button {
  position: absolute;
  cursor: pointer;
  width: 14px;
  height: 12px;
  left: 146px;
  top: 108px;
//   background: url(icon/audio-glass.png) -311px -317px no-repeat;
}
.audio-player.glass .list-panel-bk {
  position: absolute;
  width: 310px;
  height: 69px;
//   background: url(icon/audio-glass.png) -16px -152px no-repeat;
  left: 10px;
  top: 116px;
  padding: 26px;
  overflow: hidden;
  display: none;
}
.audio-player.glass .list-panel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.audio-player.glass .list-item {
  position: relative;
  float: bottom;
  white-space: nowrap;
  cursor: pointer;
  width: 310px;
  height: 25px;
  line-height: 25px;
  text-align: left;
  margin-left: 2px;
  vertical-align: middle;
  font-family: "Tahoma", "sans-serif", "Arial";
  font-size: 12px;
  background-color: transparent;
  color: #999999;
}
.audio-player.glass .list-item.selected {
  background-color: rgba(0, 200, 0, 0.1);
  color: #00cc00;
}
.audio-player.glass .list-item.selected:hover {
  background-color: rgba(0, 200, 0, 0.1);
  color: #00cc00;
}
.audio-player.glass .list-item:hover {
  background-color: transparent;
  color: #dddddd;
}
/*Start of simple*/
.audio-player.simple {
  width: 203px;
  height: 90px;
//   background: url(icon/audio-simple.png) -20px -21px no-repeat;
}
.audio-player.simple .back_button {
  position: absolute;
  cursor: pointer;
  width: 25px;
  height: 25px;
  left: 21px;
  top: 24px;
//   background: url(icon/audio-simple.png) -21px -131px no-repeat;
}
.audio-player.simple .audio-play {
  position: absolute;
  cursor: pointer;
  width: 40px;
  height: 40px;
  left: 52px;
  top: 13px;
//   background: url(icon/audio-simple.png) -57px -123px no-repeat;
}
.audio-player.simple .paused-button {
//   background: url(icon/audio-simple.png) -103px -124px no-repeat;
}
.audio-player.simple .forward_button {
  position: absolute;
  cursor: pointer;
  width: 25px;
  height: 25px;
  left: 97px;
  top: 24px;
//   background: url(icon/audio-simple.png) -156px -131px no-repeat;
}
.audio-player.simple .volume-button {
  position: absolute;
  width: 26px;
  height: 24px;
  left: 156px;
  top: 21px;
//   background: url(icon/audio-simple.png) -196px -131px no-repeat;
}
.audio-player.simple .volume-mute {
//   background: url(icon/audio-simple.png) -242px -131px no-repeat;
}
.audio-player.simple .audio-timer {
  display: none;
}
.audio-player.simple .audio-file {
  display: none;
}
/* seek */
.audio-player.simple .audio-seek {
  position: absolute;
  width: 160px;
  height: 10px;
  left: 20px;
  top: 63px;
}
.audio-player.simple .audio-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  border: 1px solid #040404;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #14161d;
}
.audio-player.simple .audio-seek .slider-range {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  -moz-border-radius: 5px 0px 0px 5px;
  -webkit-border-radius: 5px 0px 0px 5px;
  border-radius: 5px 0px 0px 5px;
  z-index: 2;
  border: 1px solid #040404;
  background: #41546b;
  background-image: -moz-linear-gradient(left, #a05ca6, #2f7a61);
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, #a05ca6),
    color-stop(1, #2f7a61)
  );
  box-shadow: inset 0 -3px 3px #2f7a61;
}
.audio-player.simple .audio-seek .slider-handle {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 12px;
  height: 12px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  z-index: 3;
  background: #cccccc;
  background-image: -moz-linear-gradient(left, #fcfcfc, #cccccc);
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, #fcfcfc),
    color-stop(1, #cccccc)
  );
}
.audio-player.simple .volume-seek {
  display: none;
}
.audio-player.simple .volume-seek .slider-total {
  display: none;
}
.audio-player.simple .volume-seek .slider-range {
  display: none;
}
.audio-player.simple .volume-seek .slider-handle {
  display: none;
}
/*Start of enjoy*/
.audio-player.enjoy {
  width: 350px;
  height: 63px;
//   background: url(icon/audio-enjoy.png) -19px -10px no-repeat;
}
.audio-player.enjoy .back_button {
  display: none;
}
.audio-player.enjoy .audio-play {
  position: absolute;
  cursor: pointer;
  width: 48px;
  height: 48px;
  left: 8px;
  top: 5px;
//   background: url(icon/audio-enjoy.png) -32px -93px no-repeat;
}
.audio-player.enjoy .paused-button {
//   background: url(icon/audio-enjoy.png) -84px -93px no-repeat;
}
.audio-player.enjoy .forward_button {
  display: none;
}
.audio-player.enjoy .volume-button {
  position: absolute;
  width: 48px;
  height: 48px;
  left: 295px;
  top: 5px;
//   background: url(icon/audio-enjoy.png) -137px -93px no-repeat;
}
.audio-player.enjoy .volume-mute {
//   background: url(icon/audio-enjoy.png) -192px -94px no-repeat;
}
.audio-player.enjoy .audio-timer {
  display: none;
}
.audio-player.enjoy .audio-file {
  display: none;
}
/* seek */
.audio-player.enjoy .audio-seek {
  position: absolute;
  width: 223px;
  height: 36px;
  left: 63px;
  top: 12px;
}
.audio-player.enjoy .audio-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
//   background: url(icon/audio-enjoy.png) -35px -171px no-repeat;
}
.audio-player.enjoy .audio-seek .slider-range {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  z-index: 2;
//   background: url(icon/audio-enjoy.png) -33px -217px no-repeat;
}
.audio-player.enjoy .audio-seek .slider-handle {
  position: absolute;
  left: 0px;
  top: -3px;
  width: 45px;
  height: 52px;
  z-index: 3;
//   background: url(icon/audio-enjoy.png) -248px -96px no-repeat;
}
.audio-player.enjoy .volume-seek {
  display: none;
}
.audio-player.enjoy .volume-seek .slider-total {
  display: none;
}
.audio-player.enjoy .volume-seek .slider-range {
  display: none;
}
.audio-player.enjoy .volume-seek .slider-handle {
  display: none;
}
/*Start of background*/
.audio-player.background {
  width: 100px;
  height: 100px;
//background:url(icon/audio-background.png) 0px 0px no-repeat;
    
}
.audio-player.background .back_button {
  display: none;
}
.audio-player.background .audio-play {
  position: absolute;
  cursor: pointer;
  width: 100px;
  height: 100px;
//   background: url(icon/audio-background.png) 0px -101px no-repeat;
  display: none;
}
.audio-player.background .paused-button {
//   background: url(icon/audio-background.png) 0px 0px no-repeat;
  display: none;
}
.audio-player.background .forward_button {
  display: none;
}
.audio-player.background .volume-button {
  display: none;
}
.audio-player.background .volume-mute {
  display: none;
}
.audio-player.background .audio-timer {
  display: none;
}
.audio-player.background .audio-file {
  display: none;
}
/* seek */
.audio-player.background .audio-seek {
  display: none;
}
.audio-player.background .audio-seek .slider-total {
  display: none;
}
.audio-player.background .audio-seek .slider-range {
  display: none;
}
.audio-player.background .audio-seek .slider-handle {
  display: none;
}
.audio-player.background .volume-seek {
  display: none;
}
.audio-player.background .volume-seek .slider-total {
  display: none;
}
.audio-player.background .volume-seek .slider-range {
  display: none;
}
.audio-player.background .volume-seek .slider-handle {
  display: none;
}
/*Start of tree*/
.audio-player.tree {
  width: 322px;
  height: 125px;
//   background: url(icon/audio-tree.png) -2px -11px no-repeat;
}
.audio-player.tree .back_button {
  position: absolute;
  cursor: pointer;
  width: 42px;
  height: 42px;
  left: 78px;
  top: 75px;
//   background: url(icon/audio-tree.png) -12px -159px no-repeat;
}
.audio-player.tree .audio-play {
  position: absolute;
  cursor: pointer;
  width: 49px;
  height: 49px;
  left: 137px;
  top: 73px;
//   background: url(icon/audio-tree.png) -62px -154px no-repeat;
}
.audio-player.tree .paused-button {
//   background: url(icon/audio-tree.png) -172px -156px no-repeat;
}
.audio-player.tree .forward_button {
  position: absolute;
  cursor: pointer;
  width: 42px;
  height: 42px;
  left: 200px;
  top: 75px;
//   background: url(icon/audio-tree.png) -122px -159px no-repeat;
}
.audio-player.tree .volume-button {
  display: none;
}
.audio-player.tree .volume-mute {
  display: none;
}
.audio-player.tree .audio-timer {
  position: absolute;
  left: 245px;
  top: 43px;
  color: #666;
  font-family: Arial, Tahoma;
  font-size: 0.6em;
  font-weight: bold;
}
.audio-player.tree .audio-file {
  position: absolute;
  left: 75px;
  top: 13px;
  color: #666;
  font-family: Arial, Tahoma;
  font-size: 0.6em;
  font-weight: bold;
}
/* seek */
.audio-player.tree .audio-seek {
  position: absolute;
  width: 160px;
  height: 7px;
  left: 72px;
  top: 48px;
}
.audio-player.tree .audio-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
//   background: url(icon/audio-tree.png) -13px -220px no-repeat;
}
.audio-player.tree .audio-seek .slider-range {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  z-index: 2;
//   background: url(icon/audio-tree.png) -13px -240px no-repeat;
}
.audio-player.tree .audio-seek .slider-handle {
  position: absolute;
  left: 0px;
  top: -4px;
  width: 14px;
  height: 14px;
  z-index: 3;
//   background: url(icon/audio-tree.png) -193px -225px no-repeat;
}
.audio-player.tree .volume-seek {
  display: none;
}
.audio-player.tree .volume-seek .slider-total {
  display: none;
}
.audio-player.tree .volume-seek .slider-range {
  display: none;
}
.audio-player.tree .volume-seek .slider-handle {
  display: none;
}
/*Start of christmas*/
.audio-player.christmas {
  width: 374px;
  height: 95px;
//   background: url(icon/audio-christmas.png) -26px -15px no-repeat;
}
.audio-player.christmas .back_button {
  position: absolute;
  cursor: pointer;
  width: 11px;
  height: 12px;
  left: 16px;
  top: 68px;
//   background: url(icon/audio-christmas.png) -40px -133px no-repeat;
}
.audio-player.christmas .audio-play {
  position: absolute;
  cursor: pointer;
  width: 6px;
  height: 12px;
  left: 57px;
  top: 68px;
//   background: url(icon/audio-christmas.png) -79px -133px no-repeat;
}
.audio-player.christmas .paused-button {
  width: 8px;
//   background: url(icon/audio-christmas.png) -106px -133px no-repeat;
}
.audio-player.christmas .forward_button {
  position: absolute;
  cursor: pointer;
  width: 11px;
  height: 12px;
  left: 93px;
  top: 68px;
//   background: url(icon/audio-christmas.png) -133px -133px no-repeat;
}
.audio-player.christmas .volume-button {
  position: absolute;
  cursor: pointer;
  width: 13px;
  height: 13px;
  left: 347px;
  top: 68px;
//   background: url(icon/audio-christmas.png) -169px -133px no-repeat;
}
.audio-player.christmas .volume-mute {
//   background: url(icon/audio-christmas.png) -200px -133px no-repeat;
}
.audio-player.christmas .audio-timer {
  position: absolute;
  left: 10px;
  top: 30px;
  color: #333;
  font-family: Arial, Tahoma;
  font-size: 0.6em;
  font-weight: bold;
}
.audio-player.christmas .audio-file {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #333;
  font-family: Arial, Tahoma;
  font-size: 0.6em;
  font-weight: bold;
}
/* seek */
.audio-player.christmas .audio-seek {
  position: absolute;
  width: 180px;
  height: 10px;
  left: 126px;
  top: 68px;
}
.audio-player.christmas .audio-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-top: 1px solid #252525;
  border-bottom: 1px solid #939393;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #1f1f1f;
}
.audio-player.christmas .audio-seek .slider-range {
  position: absolute;
  top: 1px;
  left: 0px;
  height: 100%;
  -moz-border-radius: 5px 0px 0px 5px;
  -webkit-border-radius: 5px 0px 0px 5px;
  border-radius: 5px 0px 0px 5px;
  z-index: 2;
  border-bottom: 1px solid #225d94;
  background: #4388d1;
  background-image: -moz-linear-gradient(top, #5c99e2, #a8d3e7);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #5c99e2),
    color-stop(1, #2a76bf)
  );
  box-shadow: inset 0 -3px 3px #2a76bf;
}
.audio-player.christmas .audio-seek .slider-handle {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 14px;
  height: 15px;
  z-index: 3;
//   background: url(icon/audio-christmas.png) -232px -133px no-repeat;
}
.audio-player.christmas .volume-seek {
  display: none;
}
.audio-player.christmas .volume-seek .slider-total {
  display: none;
}
.audio-player.christmas .volume-seek .slider-range {
  display: none;
}
.audio-player.christmas .volume-seek .slider-handle {
  display: none;
}
/*Start of turnonoff*/
.audio-player.turnonoff {
  width: 100px;
  height: 26px;
  background: #333;
  border-radius: 3px;
}
.audio-player.turnonoff .back_button {
  display: none;
}
.audio-player.turnonoff .audio-play {
  position: absolute;
  cursor: pointer;
  width: 22px;
  height: 22px;
  left: 0px;
  top: 2px;
//   background: url(icon/player-volume.png) no-repeat;
}
.audio-player.turnonoff .paused-button {
//   background: url(icon/player-volume-full.png) no-repeat;
}
.audio-player.turnonoff .volume-status {
  position: absolute;
  cursor: pointer;
  width: 78px;
  height: 26px;
  line-height: 26px;
  left: 22px;
  top: 0px;
  color: #cccccc;
  text-align: center;
  vertical-align: middle;
  font-family: "Tahoma", "sans-serif", "Arial";
  font-size: 14px;
}
.audio-player.turnonoff .volume-status:hover {
  color: #ffffff;
}
.audio-player.turnonoff .forward_button {
  display: none;
}
.audio-player.turnonoff .volume-button {
  display: none;
}
.audio-player.turnonoff .volume-mute {
  display: none;
}
.audio-player.turnonoff .audio-timer {
  display: none;
}
.audio-player.turnonoff .audio-file {
  display: none;
}
/* seek */
.audio-player.turnonoff .audio-seek {
  display: none;
}
.audio-player.turnonoff .audio-seek .slider-total {
  display: none;
}
.audio-player.turnonoff .audio-seek .slider-range {
  display: none;
}
.audio-player.turnonoff .audio-seek .slider-handle {
  display: none;
}
.audio-player.turnonoff .volume-seek {
  display: none;
}
.audio-player.turnonoff .volume-seek .slider-total {
  display: none;
}
.audio-player.turnonoff .volume-seek .slider-range {
  display: none;
}
.audio-player.turnonoff .volume-seek .slider-handle {
  display: none;
}
/*Start of fade*/
.audio-player.fade {
  width: 345px;
  height: 65px;
//   background: url(icon/audio-fade.png) -19px -2px no-repeat;
}
.audio-player.fade .back_button {
  position: absolute;
  cursor: pointer;
  width: 28px;
  height: 28px;
  left: 16px;
  top: 17px;
//   background: url(icon/audio-fade.png) -30px -91px no-repeat;
}
.audio-player.fade .audio-play {
  position: absolute;
  cursor: pointer;
  width: 31px;
  height: 31px;
  left: 44px;
  top: 15px;
//   background: url(icon/audio-fade.png) -64px -89px no-repeat;
}
.audio-player.fade .paused-button {
//   background: url(icon/audio-fade.png) -100px -89px no-repeat;
}
.audio-player.fade .forward_button {
  position: absolute;
  cursor: pointer;
  width: 28px;
  height: 28px;
  left: 75px;
  top: 17px;
//   background: url(icon/audio-fade.png) -138px -91px no-repeat;
}
.audio-player.fade .volume-button {
  display: none;
}
.audio-player.fade .audio-timer {
  position: absolute;
  right: 18px;
  top: 22px;
  color: #999;
  font-family: Arial, Tahoma;
  font-size: 0.6em;
  font-weight: bold;
}
.audio-player.fade .audio-file {
  display: none;
}
/* seek */
.audio-player.fade .audio-seek {
  position: absolute;
  width: 150px;
  height: 10px;
  left: 108px;
  top: 25px;
}
.audio-player.fade .audio-seek .slider-total {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
//   background: url(icon/audio-fade.png) -31px -139px no-repeat;
}
.audio-player.fade .audio-seek .slider-range {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  -moz-border-radius: 5px 0px 0px 5px;
  -webkit-border-radius: 5px 0px 0px 5px;
  border-radius: 5px 0px 0px 5px;
  z-index: 2;
//   background: url(icon/audio-fade.png) -193px -138px no-repeat;
}
.audio-player.fade .audio-seek .slider-handle {
  position: absolute;
  left: 0px;
  top: -2px;
  width: 15px;
  height: 15px;
  z-index: 3;
//   background: url(icon/audio-fade.png) -176px -98px no-repeat;
}
.audio-player.fade .volume-seek {
  display: none;
}
.audio-player.fade .volume-seek .slider-total {
  display: none;
}
.audio-player.fade .volume-seek .slider-range {
  display: none;
}
.audio-player.fade .volume-seek .slider-handle {
  display: none;
}
.volume-bar {
  vertical-align: middle;
  position: absolute;
  z-index: 999;
}
.volume-reduce {
  cursor: pointer;
  position: absolute;
}
.volume-add {
  cursor: pointer;
  position: absolute;
}
.volume-pgress {
  position: absolute;
  border: 0 none;
}
.volume-pgress span {
  cursor: pointer;
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  background: #fff;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
