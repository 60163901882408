// root
:root {
  --tw-bg-opacity: 1;
  --dark-main-color: #2c2c2c;
  --primary-color: rgb(69, 33, 137, var(--tw-bg-opacity));
  --primary-color-light: rgb(238, 233, 247);
  --text-color: #1e293b;
  --border-color: rgb(203, 213, 225);
  --book-content-color: rgb(232, 232, 232);

  --nav-admin-w-sm: 78px;
  --nav-admin-w-lg: 265px;
  --height-comp: 46px;
  --height-comp2: 54px;
  --header-height: 68px;
}
// end root

// color
$darkMainColor: var(--dark-main-color);
$overlayColor: rgba(44, 44, 44, 0.178);
$bookContentColor: var(--book-content-color);

// size

$navAdminWidthSm: var(--nav-admin-w-sm);
$navAdminWidthLg: var(--nav-admin-w-lg);

// end size

// spacing
$spacing1: 20px;
$spacing2: 16px;
// end

// Font
$primaryFont: "Inter", sans-serif;

/* BreakPoint */
// Mobile App
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

// Web App
/* $sm: 639px;
$md: 767px;
$lg: 1023px;
$xl: 1279px;
$xxl: 1535px; */
