@import "../abstracts/variables";

$violet2: #ebe1fc;

.layoutLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-color-light);
}

.bookLoading {
  h1 {
    color: #8455b2;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    position: relative;
    &:after {
      position: absolute;
      content: "...";
      -webkit-animation: Dots 2s cubic-bezier(0, 0.39, 1, 0.68) infinite;
      animation: Dots 2s cubic-bezier(0, 0.39, 1, 0.68) infinite;
    }
  } 

  .book {
    margin-bottom: 30px;
    border: 4px solid #8455b2;
    width: 60px;
    height: 45px;
    position: relative;
    perspective: 150px;
    background: $violet2;

    figure {
      display: block;
      width: 30px;
      height: 45px;
      border: 4px solid #8455b2;
      border-left: 1px solid #8455b2;
      margin: 0;
      position: absolute;
      right: -4px;
      top: -4px;
      overflow: hidden;
      transform-style: preserve-3d;
      -webkit-transform-origin: left center;
      transform-origin: left center;

      &:nth-child(1) {
        animation: pageTurn 1.2s cubic-bezier(0, 0.39, 1, 0.68) 0.6s infinite;
      }

      &:nth-child(2) {
        animation: pageTurn 1.2s cubic-bezier(0, 0.39, 1, 0.68) 0.45s infinite;
      }

      &:nth-child(3) {
        background: $violet2;
        animation: pageTurn 1.2s cubic-bezier(0, 0.39, 1, 0.68) 0.2s infinite;
      }
    }
  }
}

/* Page turn */

@keyframes pageTurn {
  0% {
    transform: rotateY(0deg);
  }
  20% {
    background: var(--primary-color-light);
  }
  40% {
    background: $violet2;
    transform: rotateY(-180deg);
  }
  100% {
    background: $violet2;
    transform: rotateY(-180deg);
  }
}

/* Dots */

@keyframes Dots {
  0% {
    content: "";
  }
  33% {
    content: ".";
  }
  66% {
    content: "..";
  }
  100% {
    content: "...";
  }
}
