html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  overflow-x: hidden;
}
// html, body, #root, .__app {
//     height: 100%;
// }
* {
  margin: 0;
  padding: 0;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
input,
textarea,
select,
button {
  outline: none;
  font: inherit;
}
input {
  line-height: normal;
}
label,
button {
  cursor: pointer;
  user-select: none !important;
}
ul {
  list-style: none;
}
a {
  text-decoration-line: none;
  border: none;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
  user-select: none !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/* Mobie: width < 740px */
@media only screen and (max-width: 46.1875em) {
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
