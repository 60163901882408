.phoneTopBar {
  position: absolute;
  width: 100%;
  height: 40px;
  left: 0;
  top: 0;
  z-index: 99;
}
.phoneTopBar .button {
  width: 40px;
  height: 40px;
  position: absolute;
  cursor: pointer;
}
.phoneTopBar .button img {
  max-height: 40px;
  object-fit: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.phoneTopBar .button .svg {
  max-height: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.phoneTopBar .button .icon {
  position: absolute;
  left: 10px;
  top: 10px;
}
.phoneTopBar .button div {
  width: 20px;
  height: 20px;
}
.phoneTopBar .button span {
  display: none;
}

.phoneBottomBar {
  position: absolute;
  width: 100%;
  height: 40px;
  bottom: 0;
  left: 0;
  z-index: 99;
}
.phoneBottomBar .button {
  width: 40px;
  height: 40px;
  position: absolute;
  cursor: pointer;
}
.phoneBottomBar .button img {
  height: 20px;
  left: 0;
  width: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.phoneBottomBar .button .svg {
  height: 20px;
  left: 1px;
  width: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.phoneBottomBar .button .icon {
  overflow: hidden;
  position: absolute;
  left: 9px;
  top: 9px;
  width: 22px;
  height: 22px;
}
.phoneBottomBar .button span {
  display: none;
}
.phoneBottomBar .button img.custom {
  left: 0;
}

.phone.extendedBar .arrow {
  position: absolute;
  width: 50px;
  height: 20px;
  left: 50%;
  cursor: pointer;
  margin-left: -25px;
  border-radius: 5px 5px 0 0;
  top: -20px;
  background-color: rgba(0, 0, 0, 0.8);
}
.phone.extendedBar .arrow img {
  width: 10px;
  margin: 5px 20px;
}

.extendedBar.phone {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  position: absolute;
  bottom: -280px;
  z-index: 99;
  border-radius: 0;
  background: #000000;
  width: 100%;
  height: 72px;
}
.extendedBar.phone .button {
  font-size: 12px;
  width: 40px;
  height: 52px;
  border-radius: 0;
  float: left;
  margin: 10px 0 0 30px;
  position: relative;
  text-align: center;
}
.extendedBar.phone .button .icon {
  overflow: hidden;
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 0 0 0 4px;
}
.extendedBar.phone .button img {
  left: -25px;
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 7px;
  margin: 0;
}
.extendedBar.phone .button .svg {
  left: 7px;
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 7px;
  margin: 0;
}
.extendedBar.phone .button span {
  top: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: white;
  line-height: 20px;
  display: block;
  position: relative;
  margin: 0 0 0 -15px;
  width: 70px;
}
.extendedBar.phone .button img.custom {
  left: 7px;
}
.extendedBar.phone .svg-box {
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin: 3px 0 0 18px;
}
.extendedBar.phone .cancel {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: -41px;
  text-align: center;
  line-height: 40px;
}

.extendedBar.phone .stage {
  height: 100%;
}
.extendedBar.phone .stage .swiper {
  height: 100%;
}
.extendedBar.phone .stage .swiper .item {
  height: 100%;
}
.extendedBar.phone .stage .swiper .dots {
  height: 20px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.extendedBar.phone .stage .swiper .dots .dot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #ffffff;
}

.phoneGotoPageContent img {
  /*width : 22px;*/
  /*height : 22px;*/
  cursor: pointer;
  margin: 4px -2px 4px 0;
}

.phoneGotoPageContent .inputButton {
  display: inline-block;
  position: relative;
  overflow: visible;
  top: 10px;
  margin-left: 7px;
  width: 25px;
  height: 30px;
  cursor: pointer;
  /*border:1px solid rgba(255,255,255,0.1);*/
}

.phoneGotoPageContent .goBtn div {
  width: 20px;
  height: 20px;
  margin: 4px;
}

.phoneGotoPageContent .btn {
  display: inline-block;
  position: relative;
  overflow: visible;
  top: 5px;
  margin-top: 12px;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  /*border:1px solid rgba(255,255,255,0.1);*/
}

.phoneGotoPageContent .btn div {
  width: 20px;
  height: 20px;
}

.phoneGotoPageContent .svg-box {
  margin-top: 5px;
}

.phoneGotoPageContent .goBtn {
  width: 30px;
  height: 30px;
  top: 0px;
  right: 0px;
  cursor: pointer;
  z-index: 5;
  position: absolute;
}

.phoneSearchBarInput {
  position: absolute;
  width: 80%;
  left: 10%;
  margin-left: -10px;
  height: 30px;
  top: 10px;
  padding: 0 10px;
  border: 1px solid #f2f2f2;
  border-radius: 15px;
  background: #ffffff;
}

.phoneSearchBarButton {
  position: absolute;
  top: 14px;
  right: 10%;
  cursor: pointer;
  z-index: 10;
  max-width: 22px;
  max-height: 22px;
}

.form_mask_phone {
  opacity: 0.5;
  background: #000000;
  z-index: 10000;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.phone .form_title {
  text-align: center;
  vertical-align: middle;
  font-family: "Arial";
  font-size: 18px;
  line-height: 20px;
  padding: 0 20px;
  margin: 0;
  margin: 15px 0;
}
.phone .form_title span {
  margin: 0;
  line-height: 20px;
  padding: 0;
}
.phone .close {
  top: 13px;
  position: absolute;
  cursor: pointer;
  left: auto;
  left: 10px;
  padding: 0;
  margin: 0;
  width: 25px;
  height: 25px;
}
.phone.rightToLeft .close {
  top: 13px;
  position: absolute;
  cursor: pointer;
  left: 10px;
}

/* .print_form_phone */
.print_form_phone {
  text-align: left;
  vertical-align: middle;
  line-height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  background: #000000;
  width: 310px;
  padding: 0;
  color: #ffffff;
  font-size: 15px;
  font-family: Calibri;
  position: absolute;
}
.print_form_phone .printButton {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.25);
  vertical-align: middle;
  text-align: center;
  margin: 10px 20px;
  width: auto;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
}
.print_form_phone .item span {
  margin-left: 10px;
  vertical-align: middle;
}

.print_form_phone {
  background: transparent;
  width: 100%;
  padding: 0;
}
.print_form_phone {
  background: transparent;
  width: 100%;
  padding: 0;
  border-radius: 0;
  border: none;
  box-shadow: none;
  filter: none;
}
.print_form_phone .item {
  cursor: pointer;
  line-height: 20px;
  vertical-align: middle;
  position: relative;
  padding: 10px 20px 10px;
  margin: 0 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.print_form_phone .item .print_icon {
  vertical-align: middle;
  position: absolute;
  left: 10px;
  top: 11px;
  width: 24px;
  height: 24px;
}
.print_form_phone.rightToLeft .item {
  text-align: right;
}
.print_form_phone.rightToLeft .item .print_icon {
  vertical-align: middle;
  position: absolute;
  right: 10px;
  left: auto;
  top: 11px;
}
.print_form_phone .printRange input {
  border-radius: 0;
  padding: 0 5px;
  width: 100px;
  height: 26px;
  border: none;
  margin-left: 10px;
  font-size: 12px;
  background-color: #d3d3d3;
}
.print_form_phone.rightToLeft .printRange input {
  margin-left: 0;
  margin-right: 10px;
}
.print_form_phone .item span {
  margin-left: 20px;
}
.print_form_phone.rightToLeft .item span {
  margin: 0 20px 0 0;
}

/* bookmark_form_phone */
.bookmark_form_phone {
  text-align: left;
  vertical-align: middle;
  line-height: 20px;
  position: absolute;
  display: block;
  z-index: 1000;
  left: -350px;
  top: 157px;
  width: 250px;
  height: 425px;
  background: #000000;
  padding: 0;
  color: #ffffff;
  font-size: 15px;
  font-family: Calibri;

  * {
    box-sizing: content-box;
  }
}
.bookmark_form_phone .inputForm {
  width: 100%;
  margin-top: 10px;
  height: 30px;
  position: relative;
}
.bookmark_form_phone .input_title {
  width: 192px;
  height: 30px;
  border: none;
  padding: 0 10px;
  position: absolute;
  left: 0;
}
.bookmark_form_phone .colorSelection {
  width: 28px;
  height: 28px;
  border: 1px solid #ffffff;
  position: absolute;
  right: 0;
}
.bookmark_form_phone .add_page {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  width: 120px;
  height: 30px;
  line-height: 30px;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.25);
}
.bookmark_form_phone .add_page.left {
  margin-right: 10px;
}
.bookmark_form_phone .list_title {
  cursor: default;
  font-size: 15px;
  height: 30px;
  width: 100%;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.1);
  line-height: 30px;
  vertical-align: middle;
  text-align: left;
}
.bookmark_form_phone .list_title span {
  padding: 0 10px;
  display: inline-block;
}
.bookmark_form_phone .list_title .page {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  width: 30px;
}
.bookmark_form_phone .list_title .description {
  width: 108px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.bookmark_form_phone .list_title .edit {
}

.bookmark_form_phone .stage {
  overflow: hidden;
  width: 100%;
  position: relative;
}
.bookmark_form_phone .swiper {
  width: 100%;
  left: 0;
  top: 0;
}

.bookmark_form_phone .progress {
  width: 15px;
  height: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
.bookmark_form_phone .progressBar {
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.25);
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  border-radius: 2px;
}

.bookmark_form_phone .removeAll {
  width: 100%;
  height: 30px;
  line-height: 30px;
  background: rgba(255, 255, 255, 0.25);
  margin-top: 5px;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  display: none;
}

.bookmark_form_phone .item {
  width: 100%;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  text-align: left;
  position: relative;
  margin-top: 0;
}
.bookmark_form_phone .item span {
  padding: 0 10px;
  display: inline-block;
}
.bookmark_form_phone .item input {
  display: inline-block;
}
.bookmark_form_phone .item .page {
  width: 30px;
}
.bookmark_form_phone .item .title {
  width: 82px;
  height: 20px;
  line-height: 20px;
  vertical-align: middle;
  border: none;
  padding: 0 10px;
  background: transparent;
}
.bookmark_form_phone .item .colorSelection {
  margin: 0;
  width: 20px;
  height: 18px;
  position: absolute;
  left: 130px;
  top: 6px;
  display: none;
}
.bookmark_form_phone .item .thumbnail {
  position: absolute;
  cursor: pointer;
}
.bookmark_form_phone .item .save {
  left: 160px;
  top: 7px;
  display: none;
}
.bookmark_form_phone .item .cancel {
  left: 190px;
  top: 7px;
  display: none;
}
.bookmark_form_phone .item .edit {
  left: 190px;
  top: 7px;
}
.bookmark_form_phone .item .delete {
  left: 214px;
  top: 7px;
}

.bookmark_form_phone.rightToLeft {
  text-align: right;
}
.bookmark_form_phone.rightToLeft .input_title {
  left: auto;
  right: 0;
  direction: rtl;
}
.bookmark_form_phone.rightToLeft .colorSelection {
  right: auto;
  left: 0;
}
.bookmark_form_phone.rightToLeft .add_page.left {
  margin-left: 10px;
  margin-right: 0;
}
.bookmark_form_phone.rightToLeft .list_title {
  text-align: right;
}
.bookmark_form_phone.rightToLeft .list_title .page {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: none;
  width: 30px;
}
.bookmark_form_phone.rightToLeft .list_title .description {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: none;
}
.bookmark_form_phone.rightToLeft .list_title .edit {
}
.bookmark_form_phone.rightToLeft .progress {
  left: 0;
  right: auto;
}
.bookmark_form_phone.rightToLeft .progressBar {
  left: 0;
  right: auto;
}

.bookmark_form_phone {
  background: transparent;
  width: 100%;
  height: 100%;
  padding: 0;
  left: 0;
  top: 0;
  margin: 0;
}
.bookmark_form_phone .stage {
  margin: 0;
  width: 100%;
  height: 100%;
}
.bookmark_form_phone .item {
  position: relative;
  width: 100%;
  height: 100px;
  line-height: 100px;
  vertical-align: middle;
  font-size: 15px;
  cursor: pointer;
}
.bookmark_form_phone .item span {
  margin: 0 10px;
  line-height: 20px;
  vertical-align: middle;
  display: inline-block;
  width: auto;
}
.bookmark_form_phone .item .thumbnail {
  height: 76px;
  vertical-align: middle;
  margin: 12px 0;
  position: relative;
}
.bookmark_form_phone {
  background: transparent;
  width: 100%;
  height: 100%;
  padding: 0;
  left: 0;
  top: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  box-shadow: none;
  filter: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.bookmark_form_phone .stage {
  margin: 0;
  width: 100%;
  height: 100%;
}
.bookmark_form_phone .item {
  position: relative;
  width: 100%;
  height: 100px;
  line-height: 100px;
  vertical-align: middle;
  font-size: 15px;
  cursor: pointer;
}
.bookmark_form_phone .item span {
  margin: 0 10px;
  display: inline-block;
  width: auto;
}
.bookmark_form_phone .item .thumbnail {
  height: 76px;
  margin: 12px 0;
  position: relative;
}
.bookmark_form_phone.rightToLeft .item .thumbnail {
  float: right;
}
.bookmark_form_phone.rightToLeft .item span {
  float: right;
  transform: translate(-20px, -50%);
  top: 50%;
  position: relative;
}
.bookmark_form_phone.rightToLeft .item .delete {
  left: 20px;
  right: auto;
}
.bookmark_form_phone.rightToLeft .item .page {
  transform: translate(0, -50%);
}
.bookmark_form_phone .item .delete {
  position: absolute;
  cursor: pointer;
  top: 30px;
  left: auto;
  right: 20px;
  width: 41px;
  height: 41px;
  background-repeat: no-repeat;
}
.bookmark_form_phone .item .delete {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAYAAACoYAD2AAADE0lEQVRYhc2Z344URRSHv+6wiX9n4qUGSLxYNMb4AhovYF8BMOKKjC8grERfwvdAbvFSWTXsGi4xZGPkYoma9dIwq+gN83lRp+lmwyxd3ds7e5KTmspU/eqb6qnqU6cKlY72KnAGeA94E1gGXgTGwAPgH+Ae8AtwC/gO+LPTSGqOL6kfqTfVR+bZI3VdXQ2d1uO2bViol9TtxqAP1W/UNXVFPamOo/046ivqlWj3sNF3W52E7oFALqsbjQG21E8bQG19HP22Globod8L8rz6IAR31A/VMhNur5ehsxO6U/WDrpCfqbMQut5h5trM7PXQn5n+FlmQVxqdrx4w3F6/2piMtbaQ56LTzLRYhgSs/FJjzHPPgjxl+o+ofnFIgJV/GeNO1TfmQRbWq/jaIQNWfi3G37SxPe2dck2r7qAXSVsfW6/6yV7IJeuN+sKCACu/EBz3g+sx5Gp8sWX/fbCvl9Yb/qpKGa/wSZRfAbOOAQeADe9qs+B4zFWorwG/A/+RIptpBlCuFS3bjUgR03PA8RI4DZTAOu0Bh7YpiacEzpTAu/HFzUyhooPn2HqU7x8D3orKz5kikPfIcyHvRPl2CbwelXuZIkPbr1GePEYK9wH+6iG43yx1XekVz6hQK5Hcx9EEKOLzvLKXfkm9okcdRIa0l6PcLUknO4BXFgQzzyqeaQlsR2V5QTDz7FSUv5XAVlTeWRDMPKt47pbARlRO9xQt5pRdreL5sXp3/wH8S967G4bbzKt39/PEu3sH2AReAM5mCA1pZ0k8m8BOFcN9fETjyYvNoHcpImE9wpE5pjOFHuEzDqbT2WY0WPRp8SfnnBYxnXeP9Lm78mYG4+IhAX5iRgaj8s/jV83UywMDXrbOBT0177Rf5zWHz6p93ZiMpyarngWJKW+4G0JD5Sd37ZGfrHxZvW1tfTK9E5/M9N42Jcn27Zvz6yfWG76mHPgNUy5zRT2hjqL9KOpVzvyGT+bM74deq6eS+6iWTKmP7+12+/CDHW4fCu11j7NCOrdX9zgvkSKYKfA39T3OBvAtHe9x/gdbb3TyIW2ytgAAAABJRU5ErkJggg==");
}
.bookmark_form_phone .item .delete:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAYAAACoYAD2AAACkklEQVRYhc3ZzW8NURjH8c+dXLqgrIl4WRARG8SiXhP1N9BYVfkDBGFnwVIbe0StaPwNdCEqIl42IqoLSsJaby2aJq7FmZHpNfdlpnPv7TeZTGbmnPP85rw+zzmVd+vXK8gWDOMY9mI3NmAzfuE35vAJL/AMP4sYquQUuQ5nMYaTiHLk/YPneIApLHeasVORFYziBnbmENaMr7iFSdTbGu9A5G48xJFVCsvipfDzc60StWuus3ijOwLF5b7FSKtErURewmNsKlFUFoN4hMvNEjQTeRl3hL7YCyqYwJWsj1kiz2C8m4pacDu2v4LGgbNH6IODPRKVRQ2HMZu8SNdkRZgS+ilQbH9SqqulRY7q3ijOyxDOJw9Jc6/DZ+VM1GUxL8zRy0lNjlhbAmGHeP5MRI6VUeqBpaV/V0mMEZp7K77L5ywUEvJ+YCBvlj/YFuGUnAJ7SIThKo4WyV2gVopyoop9RXPnafJV/NT+CLuK5u4R26uCu78qWtVSCSN9U6QkVywR03gvgcEIC2WV1iVqkRDZrWUWInzpt4o2fIvwsd8q2vAhwkwZJSUjvPFeAs+rmBZi38LxTIkjuZE6piP8EOLftchL/KjGD3cVWMN7sH7fY6VnPic4mmuF/zzzZdzsn55Mboo3tdIhbUUY6UN9EpXmlRAU1lnp7NaFCK3WB1FpakLk+m+3rdEjn8VFHWzHdYl6bH82/TIrbHiCa71QlMH12P4KmsU247iqdzVaj+3dzvrYKgCbwDksdkFUmsXYzkSzBO2ixCkcxOsSRaV5jUOxnaZ0EsrOCdPSBWGCLYP5uLwhYXunJUVOH0ZiA8flP314gfu6dPqQxRacFtb85BxnoxAzLQh9LTnHmcFTBc9x/gIyhJDrY4ZjgQAAAABJRU5ErkJggg==");
}

/* search_form_phone */
.search_form_phone {
  text-align: left;
  vertical-align: middle;
  line-height: 20px;
  position: absolute;
  z-index: 1000;
  left: -300px;
  top: 0;
  width: 250px;
  height: 548px;
  background: #000000;
  padding: 0;
  color: #ffffff;
  font-size: 15px;
  font-family: Calibri;
  position: absolute;
}
.search_form_phone .result {
  margin-top: 10px;
}

.search_form_phone .stage {
  overflow: hidden;
  width: 250px;
  height: 460px;
  position: relative;
  margin: 10px 0 0 0;
}
.search_form_phone .swiper {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.search_form_phone .progress {
  width: 15px;
  height: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
.search_form_phone .progressBar {
  width: 5px;
  height: 5px;
  opacity: 0.25;
  background: #ffffff;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  border-radius: 2px;
}

.search_form_phone .item {
  padding: 10px 20px;
  cursor: pointer;
}
.search_form_phone .item .description {
  font-size: 15px;
  margin-top: 3px;
  opacity: 0.6;
}
.search_form_phone .item:hover {
  background: rgba(255, 255, 255, 0.25);
}

.search_form_phone.rightToLeft {
  text-align: right;
}
.search_form_phone.rightToLeft .progress {
  left: 0;
  right: auto;
}
.search_form_phone.rightToLeft .progressBar {
  left: 0;
  right: auto;
}

.search_form_phone {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  width: 100%;
  height: 100%;
  padding: 0;
  left: 0;
  top: 0;
  background: transparent;
}
.search_form_phone .stage {
  width: 100%;
  position: relative;
  margin: 0;
}
.search_form_phone {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  width: 100%;
  height: 100%;
  padding: 0;
  left: 0;
  top: 0;
  background: transparent;
  border-radius: 0;
  border: none;
  box-shadow: none;
  filter: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.search_form_phone .result {
  margin: 0;
  height: 35px;
  line-height: 35px;
  background: rgba(255, 255, 255, 0.05);
  padding: 0 20px;
}
.search_form_phone .stage {
  width: 100%;
  position: relative;
  margin: 0;
}

/* tableofcontent_form_phone */
.tableofcontent_form_phone {
  text-align: left;
  vertical-align: middle;
  line-height: 20px;
  position: absolute;
  z-index: 1000;
  left: -300px;
  top: 150px;
  width: 250px;
  height: 548px;
  background: #000000;
  padding: 0;
  color: #ffffff;
  font-size: 15px;
  font-family: Calibri;
}

.tableofcontent_form_phone .swiper {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.tableofcontent_form_phone .progress {
  width: 15px;
  height: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
.tableofcontent_form_phone .progressBar {
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.25);
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  border-radius: 2px;
}

.tableofcontent_form_phone .item {
  font-size: 15px;
  cursor: pointer;
  position: relative;
  margin-right: 0;
  margin-left: 0;
  line-height: 40px;
  padding-left: 10px;
}
.tableofcontent_form_phone .item .arrow {
  padding: 10px 10px 10px 0;
  position: absolute;
  top: 0;
}
.tableofcontent_form_phone .item .description {
  display: inline-block;
}
.tableofcontent_form_phone .item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.tableofcontent_form_phone.rightToLeft {
  text-align: right;
}
.tableofcontent_form_phone.rightToLeft .item {
  margin-right: 0;
  margin-left: 0;
}
.tableofcontent_form_phone.rightToLeft .item .arrow {
  padding: 10px 0 10px 10px;
}
.tableofcontent_form_phone.rightToLeft .progress {
  left: 0;
  right: auto;
}
.tableofcontent_form_phone.rightToLeft .progressBar {
  left: 0;
  right: auto;
}

.tableofcontent_form_phone {
  background: transparent;
  width: 100%;
  height: 100%;
  padding: 0;
  left: 0;
  top: 0;
  margin: 0;
}
.tableofcontent_form_phone {
  background: transparent;
  width: 100%;
  height: 100%;
  padding: 0;
  left: 0;
  top: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  box-shadow: none;
  filter: none;
}
.tableofcontent_form_phone .stage {
  position: relative;
  margin: 0 20px;
}

.phone .searchButton {
  position: absolute;
  top: 70px;
  right: 20px;
  cursor: pointer;
  z-index: 10;
  max-width: 25px;
  max-height: 25px;
  transform: translate(0, -50%);
}
.phone .input_search {
  background-color: #fff;
  border-radius: 5px;
  margin: 0 20px;
  height: 40px;
  line-height: 40px;
  padding: 0 25px 0 10px;
  width: 275px;
  border: 1px solid transparent;
}
.phone.rightToLeft .searchButton {
  left: 20px;
  right: 0;
}
.phone.rightToLeft .input_search {
  padding: 0 10px 0 25px;
}

/* about form */
.about_form_phone {
  vertical-align: middle;
  line-height: 20px;
  text-align: center;
  width: 100%;
  height: auto;
  padding: 0;
  color: #ffffff;
  font-size: 15px;
  font-family: Calibri;
  position: absolute;
}
.about_form_phone .content_title {
  border: 1px solid transparent;
  top: 51px;
  cursor: pointer;
  width: 105px;
  height: 34px;
  position: absolute;
  line-height: 34px;
  vertical-align: middle;
  text-align: center;
}
.about_form_phone .contact_title {
  left: 51%;
}
.about_form_phone .description_title {
  right: 51%;
}
.about_form_phone .focus {
  background: rgba(255, 255, 255, 0.25);
}
.about_form_phone .content {
  width: auto;
}
.about_form_phone .content1 {
  display: none;
  height: 220px;
  overflow: auto;
}
.about_form_phone .content2 {
  height: 220px;
  overflow: hidden;
  position: relative;
}
.about_form_phone .description {
  text-align: left;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.about_form_phone .item {
  position: relative;
  min-height: 18px;
  text-align: left;
  cursor: pointer;
  margin: 0;
  vertical-align: middle;
  padding: 10px 20px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.about_form_phone .item .title {
  left: 20px;
  top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  width: 70px;
}
.about_form_phone .content2 .item .content {
  margin: 0;
  width: auto;
}
.about_form_phone .content1 .item .content {
  margin: 0 0 0 80px;
  width: auto;
}
.about_form_phone .content1 .item {
  height: 34px;
  line-height: 34px;
}
.about_form_phone a {
  text-decoration: underline;
}
.about_form_phone .aboutLogo {
  width: 300px;
  height: 70px;
  margin: 0 20px;
  display: inline-block;
}

.about_form_phone .swiper {
  width: 98%;
  position: absolute;
  left: 0;
  top: 0;
}
.about_form_phone .progress {
  width: 15px;
  height: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
.about_form_phone .progressBar {
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.25);
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  border-radius: 2px;
}

.about_form_phone.rightToLeft {
  text-align: right;
}
.about_form_phone.rightToLeft .item .title {
  left: auto;
  right: 20px;
}
.about_form_phone.rightToLeft a {
  float: right;
}
.about_form_phone.rightToLeft a.aboutLogo {
  float: inherit;
}
.about_form_phone.rightToLeft .content2 .item .content {
  margin: 0;
  text-align: right;
}
.about_form_phone.rightToLeft .content1 .item .content {
  margin: 0 80px 0 0;
  text-align: right;
}
.about_form_phone.rightToLeft .item .title {
  text-align: right;
}
.about_form_phone .description.item .content {
  margin: 0px;
}
.about_form_phone.rightToLeft .content {
  margin: 0 65px 0 0;
}
.about_form_phone.rightToLeft .item {
  text-align: right;
}

/* share_form_phone */
.share_form_phone {
  border-radius: 0;
  border: none;
  box-shadow: none;
  filter: none;
}
.share_form_phone .item .logo {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  display: block;
  float: none;
}
.share_form_phone .item .logo_title {
  line-height: 30px;
}
.share_form_phone .item .logo div {
  width: 40px;
  height: 40px;
}
.share_form_phone .copy {
  width: 74%;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.4);
  color: #fff;
  position: absolute;
  top: 320px;
  left: 12%;
  right: auto;
  vertical-align: middle;
  text-align: center;
}

.share_form_phone {
  z-index: 10001;
  padding: 0;
  top: auto;
  border-radius: 10px 10px 0 0;
  border: none;
  box-shadow: none;
  filter: none;
}

.share_form_phone .form_title {
  text-align: center;
  top: 0;
  left: 0;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background: transparent;
  width: 100%;
  font-family: "Arial";
  font-size: 15px;
  line-height: 40px;
  position: relative;
  margin: 0;
  height: 40px;
  padding: 0;
}
.share_form_phone .close {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  top: auto;
  bottom: 0;
  left: 0;
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  font-size: 15px;
}
.share_form_phone.rightToLeft .close {
  top: auto;
  left: 0;
}
.share_form_phone .stage {
  position: absolute;
  top: 60px;
  left: 10px;
  right: 10px;
  bottom: 60px;
  margin: auto;
  width: auto;
}
.share_form_phone .back {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 10px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
}
.share_form_phone .back span {
  font-family: "Arial";
  font-size: 12px;
  margin-left: 10px;
}
.share_form_phone .back img {
  vertical-align: middle;
}
.share_form_phone .qrcode {
  position: absolute;
  top: 54px;
  left: 0;
  right: 0;
  bottom: 54px;
  margin: auto;
  text-align: center;
}
.share_form_phone .qrcode .checked {
  background: rgba(255, 255, 255, 0.2);
}
.share_form_phone .qrcode .QRcode > canvas {
  border: 5px solid #fff;
}
.share_form_phone .qrcode > div {
  display: none;
}
.share_form_phone .qrcode > div.showQR {
  display: block;
}
.share_form_phone .qrcode .title {
  position: absolute;
  text-align: center;
  bottom: 0;
  width: 100%;
}

.share_form_phone .qrcode img {
  width: 100px;
  height: 100px;
  padding: 5px;
  background: #fff;
}
.share_form_phone .qrcode span {
  cursor: pointer;
  font-size: 15px;
  margin-top: 15px;
  text-align: center;
  vertical-align: middle;
  width: 150px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  display: inline-block;
  background: rgba(255, 255, 255, 0);
  border: 1px solid #333333;
}
.share_form_phone .item {
  padding: 0;
  vertical-align: middle;
  font-size: 12px;
  position: relative;
  display: inline-block;
  width: 20%;
  margin-bottom: 15px;
  margin-left: 4%;
  text-align: center;
}
.share_form_phone .qrcode.two span {
  width: 95px;
}
.share_form_phone .qrcode.two .left {
  border-radius: 20px 0 0 20px;
}
.share_form_phone .qrcode.two .right {
  border-radius: 0 20px 20px 0;
}

.share_form_phone .item .logo {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  display: block;
  float: none;
}
.share_form_phone .item .logo_title {
  line-height: 30px;
  font-size: 12px;
}
.share_form_phone .item .logo div {
  width: 40px;
  height: 40px;
}

.share_form_phone .progress {
  width: 10px;
  right: 0;
  position: absolute;
}
.share_form_phone .progressBar {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 0px;
  width: 10px;
  border-radius: 5px;
}

/* thumbnail_form_phone */
.thumbnail_form_phone {
  * {
    box-sizing: content-box;
  }

  input {
    color: var(--text-color);
  }

  &.phone {
    .searchButton {
      margin-right: 4px;
    }
  }
}

.thumbnail_form_phone .result {
  text-align: left;
  margin: 0;
  height: 35px;
  line-height: 35px;
  background: rgba(255, 255, 255, 0.05);
  padding: 0 20px;
}
.thumbnail_form_phone.rightToLeft .result {
  text-align: right;
}
.thumbnail_form_phone .input_search {
  background-color: #fff;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  padding: 0 25px 0 10px;
  width: 305px;
  border: 1px solid transparent;
}

/* setting_form_phone */
.setting_form_phone {
  right: auto;
}
.setting_form_phone {
  width: 100%;
  height: 100%;
  left: 0;
  vertical-align: middle;
  font-family: Arial, Tahoma;
  background: transparent;
  z-index: 1;
}
.setting_form_phone .stage {
  margin: 0;
  width: 100%;
  height: 100%;
}
.setting_form_phone .scrollBox {
  position: absolute;
  width: 100%;
}
.setting_form_phone .scrollBox .item {
  position: relative;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
}
.setting_form_phone .scrollBox .item img {
  position: absolute;
  cursor: pointer;
  width: 25px;
  height: 25px;
  top: 13px;
  right: 18px;
}
.setting_form_phone .scrollBox .item .title {
  line-height: 50px;
  font-size: 15px;
  padding: 0 18px;
  text-align: left;
}
.setting_form_phone.rightToLeft .item .title {
  text-align: right;
}
.setting_form_phone.rightToLeft .item img {
  right: auto;
  left: 18px;
}

/* language_form_phone */
.language_form_phone {
  width: 100%;
  height: 100%;
  left: 0;
  vertical-align: middle;
  font-family: Arial, Tahoma;
  background: transparent;
  z-index: 1;
}
.language_form_phone .stage {
  margin: 0;
  width: 100%;
  height: 100%;
}
.language_form_phone .scrollBox {
  position: relative;
  width: 100%;
  top: 0;
  transform: none;
  -webkit-transform: none;
  -o-transform: none;
  -ms-transform: none;
  -moz-transform: none;
}
.language_form_phone .scrollBox .item {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
}
.language_form_phone .scrollBox .item img {
  display: none;
  position: absolute;
  cursor: pointer;
  width: 25px;
  height: 25px;
  top: 13px;
  right: 15px;
}
.language_form_phone .scrollBox .item .title {
  line-height: 50px;
  font-size: 15px;
  text-align: left;
  padding: 0;
  width: 100%;
  margin-left: 0;
  left: 0;
}
.language_form_phone .scrollBox .item.focus .title {
  border: none;
}
.language_form_phone .scrollBox .item.focus img {
  display: block;
}

/* copy_form */
.copy_form {
  font-family: Calibri;
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 80%;
  height: 170px;
  padding: 10px;
  margin: auto;
  vertical-align: middle;
  color: #ffffff;
}
.copy_form textarea {
  vertical-align: top;
  top: 0;
  left: 0;
  border-radius: 5px;
  height: 130px;
  width: 100%;
  background: #ffffff;
  font-size: 15px;
  color: #000000;
  padding: 0;
  margin: 0;
  border: none;
}
.copy_form p {
  position: absolute;
  top: 152px;
  margin-right: 100px;
  line-height: 15px;
  font-size: 15px;
  text-align: left;
}
.copy_form .cancel {
  cursor: pointer;
  border-radius: 2px;
  position: absolute;
  right: 10px;
  top: 150px;
  height: 27px;
  line-height: 27px;
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 15px;
  vertical-align: middle;
  padding: 0 15px;
}

/* tel_form */
.tel_form {
  font-family: Calibri;
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 85%;
  margin: auto;
  vertical-align: middle;
  color: #ffffff;
}
.tel_form .form_title {
  margin: auto;
  height: 40px;
  width: 100%;
  background: #000000;
  line-height: 40px;
}
.tel_form .stage {
  overflow: hidden;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.8);
}
.tel_form .swiper {
  width: 100%;
  position: relative;
  left: 0;
  top: 0;
  max-height: 50%;
}
.tel_form .progress {
  width: 15px;
  height: 5px;
  position: absolute;
  bottom: 0;
  right: 4px;
  cursor: pointer;
}
.tel_form .progressBar {
  width: 5px;
  height: 5px;
  opacity: 0.25;
  background: #ffffff;
  position: absolute;
  right: 4px;
  top: 0;
  cursor: pointer;
  border-radius: 2px;
}
.tel_form .swiper .item {
  position: relative;
  font-size: 15px;
  margin: 0 10px;
  height: 70px;
  line-height: 70px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.tel_form .swiper .item .number {
  max-width: calc(100% - 120px);
  float: left;
  margin-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tel_form .swiper .item a {
  display: inline-block;
  color: #ffffff;
  position: absolute;
  right: 0;
  margin: 20px;
  border-radius: 5px;
  padding: 5px 20px;
  border: 1px solid rgba(255, 255, 255, 0.9);
  height: 20px;
  line-height: 20px;
  vertical-align: middle;
}

.doublepage_form {
  font-family: Calibri;
  overflow: hidden;
  width: 80%;
  margin: auto;
  vertical-align: middle;
  color: #575757;
  background: #fff;
}
.doublepage_form .form_title {
  margin: auto;
  width: 100%;
  color: #393939;
  font-size: 16px;
  padding-top: 5px;
}
.doublepage_form .secondTitle {
  overflow: hidden;
  width: 100%;
  position: relative;
  color: #353535;
  font-size: 13px;
  line-height: 22px;
}
.doublepage_form .itemList {
  overflow: hidden;
  width: 100%;
  position: relative;
  color: #79797a;
  font-size: 13px;
}
.doublepage_form .itemList .item {
  line-height: 22px;
}
.doublepage_form .closeButton {
  overflow: hidden;
  width: 100%;
  height: 38px;
  line-height: 38px;
  position: relative;
  color: #639fe7;
  font-size: 14px;
  border-top: 1px solid #e8e0e0;
  margin-top: 10px;
}
.doublepage_form img {
  overflow: hidden;
  width: 100%;
  display: block;
}
.doublepage_form .wordBox {
  padding-top: 10px;
  /* padding-bottom:15px; */
}
.videogallery_form_phone {
  text-align: left;
  vertical-align: middle;
  line-height: 20px;
  position: absolute;
  z-index: 1000;
  color: #ffffff;
  margin: auto;
  width: 80%;
  background: #000000;
  padding: 15px 20px 20px 20px;
  font-size: 15px;
  font-family: Calibri;
  position: absolute;
  overflow: hidden;
}
.win10_border.videogallery_form_phone {
  border-radius: unset;
}
.videogallery_form_phone .form_title {
  font-size: 18px;
  line-height: 20px;
}
.videogallery_form_phone .title {
  font-size: 18px;
  line-height: 20px;
}
.videogallery_form_phone .video {
  width: 100%;
  height: 180px;
  background: rgba(255, 255, 255, 0.25);
  margin-top: 15px;
}
.videogallery_form_phone .close {
  top: 16px;
  right: 20px;
  padding: 3px;
  position: absolute;
  cursor: pointer;
}
.videogallery_form_phone .video_list {
  width: 100%;
  height: 120px;
  margin-top: 6px;
}
.videogallery_form_phone .info {
  margin-top: 5px;
}
.videogallery_form_phone .info .description {
  width: 100%;
}
.videogallery_form_phone .description {
  opacity: 0.6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.videogallery_form_phone .stage {
  width: 100%;
  position: relative;
  height: 100px;
  overflow: hidden;
}
.videogallery_form_phone .swiper {
  width: max-content;
  position: absolute;
}

.videogallery_form_phone .progress {
  width: 15px;
  height: 5px;
  position: absolute;
  bottom: -105px;
  right: -5px;
}
.videogallery_form_phone .progressBar {
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.25);
  position: absolute;
  right: 5px;
  top: 0;
  cursor: pointer;
  border-radius: 2px;
}

.videogallery_form_phone .item {
  width: 80px;
  padding-right: 10px;
  min-height: 42px;
  margin-top: 5px;
  position: relative;
  display: inline-block;
}
.videogallery_form_phone .item .title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.videogallery_form_phone .item .thumbnail {
  background-color: rgba(255, 255, 255, 0.25);
  width: 80px;
  height: 50px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 3px;
}
.videogallery_form_phone .item:hover {
  text-decoration: underline;
}

.videogallery_form_phone.rightToLeft {
  text-align: right;
}
.videogallery_form_phone.rightToLeft .video_list {
  left: 15px;
  right: auto;
}
.videogallery_form_phone.rightToLeft .item {
  padding-right: 61px;
  padding-left: 0;
  margin-left: 5px;
}
.videogallery_form_phone.rightToLeft .item .thumbnail {
  left: auto;
  right: 0;
}
.videogallery_form_phone.rightToLeft .progress {
  left: -5px;
  right: auto;
}

/* download_form_phone */
.download_form_phone {
  font-size: 15px;
  width: 100%;
  height: 100%;
  left: 0;
  vertical-align: middle;
  font-family: Arial, Tahoma;
  background: transparent;
  z-index: 1;
}
.download_form_phone .stage {
  margin: 0;
  width: 100%;
  height: 100%;
}
.download_form_phone .content {
  position: relative;
}

.download_form_phone .content2 {
  height: 196px;
}
.download_form_phone .pictureList_win10 {
  margin-left: -75px;
  left: 50%;
  border: none;
}

.download_form_phone .downloadButton {
  cursor: pointer;
  width: auto;
  font-size: 15px;
  height: 40px;
  margin: 10px 20px;
  background: rgba(255, 255, 255, 0.25);
  line-height: 40px;
  vertical-align: middle;
  text-align: center;
}
.download_form_phone .item {
  cursor: pointer;
  line-height: 20px;
  height: 20px;
  vertical-align: middle;
  position: relative;
  margin: 0 20px;
  padding: 10px 20px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.download_form_phone .item span {
  margin-left: 12px;
  vertical-align: middle;
}
.download_form_phone .item img {
  vertical-align: middle;
  position: absolute;
  left: 10px;
  top: 13px;
}
.download_form_phone .downloadAttachments input {
  padding: 0 5px;
  width: 102px;
  height: 30px;
  border: none;
  margin-left: 10px;
  font-size: 12px;
}
.download_form_phone .content1 select {
  margin: 10px;
  width: 80%;
  color: #989898;
  height: 30px;
  border: none;
  border-radius: 0;
  outline: none;
}
.download_form_phone .select_content {
  margin: 0 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.download_form_phone.rightToLeft .item img {
  vertical-align: middle;
  position: absolute;
  right: 10px;
}

/* calendar_form_phone */
.calendar_form_phone {
  z-index: 10001;
}
.calendar_form_phone .head {
  border: 1px solid rgba(0, 0, 0, 0.04);
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 50px;
  color: #333940;
}
.calendar_form_phone .title2 {
  top: 12px;
  margin-left: 20px;
  position: relative;
  padding-left: 30px;
  color: #333940;
  font-size: 16px;
  height: 25px;
  line-height: 25px;
  vertical-align: middle;
  font-weight: bold;
}
.calendar_form_phone .title2 img {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
  left: 0;
}
.calendar_form_phone .close {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 10px;
  right: 20px;
  left: auto;
}
.calendar_form_phone .close img {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.calendar_form_phone .dataButton {
  position: absolute;
  width: 122px;
  height: 28px;
  line-height: 28px;
  vertical-align: middle;
  top: 11px;
  background: #f3f3f3;
  left: 50%;
  margin-left: -71px;
  border-radius: 37px;
  font-size: 12px;
  text-align: center;
  padding-right: 20px;
  /* pointer-events: none; */
}
.calendar_form_phone .dataButton img {
  position: absolute;
  top: 11px;
  right: 20px;
  width: 10px;
  height: 5px;
  /* pointer-events: none; */
}
.calendar_form_phone .dataButton #date-group {
  background: #f3f3f3;
  padding: 0;
  padding-right: 20px;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 37px;
  text-align: center;
  font-size: 14px;
}

.calendar_form_phone .dataButton #date-group::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #333940;
}
.calendar_form_phone .dataButton #date-group:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #333940;
}
.calendar_form_phone .dataButton #date-group::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #333940;
}
.calendar_form_phone .dataButton #date-group:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #333940;
}

.calendar_form_phone .loading {
  bottom: 0;
  top: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.calendar_form_phone .item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  padding: 10px 20px 10px 20px;
  height: 100px;
  vertical-align: middle;
  text-align: left;
  position: relative;
}
.calendar_form_phone .item .h2 {
  margin-left: 100px;
  color: #333940;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.calendar_form_phone .item .time {
  margin-top: 10px;
  margin-left: 100px;
  line-height: 16px;
  color: #666666;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.calendar_form_phone .item .image {
  width: 80px;
  height: 100px;
  position: absolute;
  left: 20px;
  top: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.calendar_form_phone .tips {
  margin-top: 24px;
  margin-bottom: 24px;
  color: #999999;
  font-size: 12px;
  text-align: center;
}
.calendar_form_phone .item .tip {
  margin-top: 8px;
  color: #666666;
  background: #f4f5f7;
  font-size: 10px;
  text-align: center;
  width: 60px;
  height: 18px;
  line-height: 18px;
  vertical-align: middle;
  text-align: center;
  margin-left: 100px;
}

.calendar_form_phone .search {
  height: 40px;
  width: 100%;
  position: relative;
  margin-top: 17px;
}
.calendar_form_phone .searchBar {
  width: 247px;
  height: 36px;
  position: absolute;
  left: 16px;
}
.calendar_form_phone .searchInput {
  border: 1px solid #dddddd;
  padding: 10px;
  width: 227px;
  height: 16px;
  font-size: 13px;
  vertical-align: middle;
  text-align: left;
  position: absolute;
  left: 0;
  top: 0;
}
.calendar_form_phone .searchButton {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 5px;
}
.calendar_form_phone .searchCancleButton {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 5px;
}
.calendar_form_phone .dateButton {
  width: 118px;
  height: 36px;
  line-height: 36px;
  vertical-align: middle;
  text-align: center;
  position: absolute;
  right: 19px;
  background: #363636;
  color: #ffffff;
  font-size: 13px;
  padding-left: 20px;
}
.calendar_form_phone .dateButton img {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 20px;
  top: 10px;
}

.calendar_form_phone .calendar_empty {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 181px;
  display: none;
}
.calendar_form_phone .calendar_empty img {
  width: 314px;
  height: 130px;
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -157px;
}
.calendar_form_phone .calendar_empty p {
  text-align: center;
  color: #666666;
  font-size: 14px;
  top: 141px;
  position: relative;
}

/* articlelist_form_phone */
.articlelist_form_phone {
  z-index: 10001;
}
.articlelist_form_phone .head {
  position: relative;
  padding-bottom: 20px;
  overflow: hidden;
  width: 100%;
  height: 30px;
  color: #333940;
}
.articlelist_form_phone .title2 {
  top: 12px;
  margin-left: 20px;
  position: relative;
  padding-left: 30px;
  color: #333940;
  font-size: 20px;
  height: 25px;
  line-height: 25px;
  vertical-align: middle;
  font-weight: bold;
}
.articlelist_form_phone .title2 img {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
  left: 0;
}
.articlelist_form_phone .close {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 6px;
  right: 20px;
  left: auto;
}
.articlelist_form_phone .close img {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.articlelist_form_phone .loading {
  bottom: 0;
  top: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.articlelist_form_phone .list {
  font-size: 13px;
  margin-bottom: 20px;
  line-height: 30px;
  height: 30px;
  margin-left: 20px;
  margin-right: 20px;
  vertical-align: middle;
  text-align: left;
  position: relative;
  background: #eeeeee;
}
.articlelist_form_phone .list .red {
  text-align: center;
  width: 20px;
  height: 100%;
  background: #b6272f;
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
}
.articlelist_form_phone .list .gray {
  margin-left: 30px;
  color: #333940;
}

.articlelist_form_phone .item {
  background: #eeeeee;
  border-radius: 4px;
  margin: 0 20px 20px 20px;
  padding: 20px;
  vertical-align: middle;
  text-align: left;
  position: relative;
}
.articlelist_form_phone .item .h2 {
  line-height: 22px;
  color: #333940;
  font-size: 16px;
  font-weight: bold;
}
.articlelist_form_phone .item .description {
  line-height: 16px;
  display: inline-block;
  color: #666666;
  font-size: 13px;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.articlelist_form_phone {
  font-family: "Open Sans", Arial, "Hiragino Sans GB", "Microsoft YaHei",
    å¾®è½¯é›…é»‘, STHeiti, "WenQuanYi Micro Hei", SimSun, sans-serif;
}

.reader_form_phone .image {
  max-width: 100%;
  height: auto;
  width: auto;
  margin-left: 50%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  margin-bottom: 20px;
}

/* reader_form_phone */
.reader_form_phone {
  font-family: "Open Sans", Arial, "Hiragino Sans GB", "Microsoft YaHei",
    å¾®è½¯é›…é»‘, STHeiti, "WenQuanYi Micro Hei", SimSun, sans-serif;
}
.reader_form_phone {
  z-index: 10001;
}
.reader_form_phone .headline {
  font-size: 24px;
  color: #333;
  font-weight: bold;
  line-height: 1.36em;
  margin-bottom: 20px;
}
.reader_form_phone .classhead {
  font-size: 16px;
  color: #999;
  /* font-weight: bold; */
  line-height: 30px;
  margin-bottom: 20px;
}
.reader_form_phone .subtitle {
  font-size: 16px;
  color: #999;
  line-height: 30px;
  margin-bottom: 20px;
}
.reader_form_phone .paragraph {
  font-size: 18px;
  color: #2f2f2f;
  line-height: 30px;
  margin-bottom: 25px;
  text-align: justify;
  word-wrap: break-word;
  text-indent: 30px !important;
  background-color: transparent !important;
}
.reader_form_phone .paragraphTitle,
.paragraphtitle {
  font-weight: bold;
  font-size: 18px;
  color: #2f2f2f;
  line-height: 30px;
  margin-bottom: 25px;
  text-align: justify;
  word-wrap: break-word;
  text-indent: 30px !important;
  background-color: transparent !important;
}
.reader_form_phone .quote {
  font-size: 20px;
  color: #333940;
  line-height: 30px;
  text-indent: 30px;
  margin-bottom: 20px;
}
.reader_form_phone .quote:first-letter {
  /* background: url(style/icon/open-quote.gif) no-repeat left top; */
  /* padding-left: 18px;
    font: italic 1.4em Georgia, "Times New Roman", Times, serif; */
}
.reader_form_phone .byline {
  font-size: 12px;
  color: #999;
  line-height: 1.5;
  margin-bottom: 20px;
  word-break: break-all;
}
.reader_form_phone .date {
  font-size: 12px;
  color: #999;
  line-height: 1.5;
  margin-bottom: 20px;
  word-break: break-all;
}
.reader_form_phone .notes {
  font-size: 12px;
  color: #999;
  line-height: 1.5;
  margin-bottom: 20px;
  word-break: break-all;
}

/* article_form_phone */
.article_form_phone {
  z-index: 10001;
}
.article_form_phone .head {
  position: relative;
  padding-bottom: 20px;
  overflow: hidden;
  width: 100%;
  height: 30px;
  color: #333940;
}
.article_form_phone .title2 {
  text-align: left;
  top: 12px;
  margin-left: 20px;
  position: relative;
  padding-left: 30px;
  color: #333940;
  font-size: 20px;
  height: 25px;
  line-height: 25px;
  vertical-align: middle;
  font-weight: bold;
}
.article_form_phone .title2 img {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
  left: 0;
}
.article_form_phone .close {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 6px;
  right: 20px;
  left: auto;
}
.article_form_phone .close img {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.article_form_phone .loading {
  bottom: 0;
  top: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.phoneBottomBar .mixButton {
  font-size: 12px;
  width: 40px;
  height: 52px;
  position: absolute;
  cursor: pointer;
  top: 5;
  text-align: center;
}
.phoneBottomBar .mixButton img {
  height: 20px;
  left: 0;
  width: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.phoneBottomBar .mixButton .svg {
  height: 20px;
  left: 1px;
  width: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.phoneBottomBar .mixButton .icon {
  overflow: hidden;
  position: absolute;
  left: 9px;
  top: 9px;
  width: 22px;
  height: 22px;
}
.phoneBottomBar .mixButton span {
  bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: white;
  line-height: 20px;
  display: block;
  position: relative;
  margin: 0 0 0 -15px;
  width: 70px;
  position: absolute;
}
.phoneBottomBar .mixButton img.custom {
  left: 0;
}

.phoneTopBar .mixButton {
  font-size: 12px;
  width: 40px;
  height: 52px;
  position: absolute;
  cursor: pointer;
  top: 5;
  text-align: center;
}
.phoneTopBar .mixButton img {
  max-height: 40px;
  position: absolute;
  top: 10px;
  left: 0;
  bottom: 0;
  margin: auto;
}
.phoneTopBar .mixButton .svg {
  height: 20px;
  left: 1px;
  width: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.phoneTopBar .mixButton .icon {
  overflow: hidden;
  position: absolute;
  left: 9px;
  top: 9px;
  width: 22px;
  height: 22px;
}
.phoneTopBar .mixButton span {
  bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: white;
  line-height: 20px;
  display: block;
  position: relative;
  margin: 0 0 0 -15px;
  width: 70px;
  position: absolute;
}
.phoneTopBar .mixButton img.custom {
  left: 0;
}

.phoneTopBar .textButton {
  font-size: 12px;
  width: 40px;
  height: 40px;
  position: absolute;
  cursor: pointer;
  top: 5;
  text-align: center;
}
/* .phoneTopBar .textButton img{max-height : 40px;position : absolute;top:10px;left:0;bottom:0;margin:auto;} */
.phoneTopBar .textButton .svg {
  display: none;
}
.phoneTopBar .textButton .icon {
  display: none;
}
.phoneTopBar .textButton span {
  top: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: white;
  line-height: 20px;
  display: block;
  position: relative;
  margin: 0 0 0 -15px;
  width: 70px;
  position: absolute;
}

.phoneBottomBar .textButton {
  font-size: 12px;
  width: 40px;
  height: 40px;
  position: absolute;
  cursor: pointer;
  top: 5;
  text-align: center;
}
.phoneBottomBar .textButton img {
  display: none;
}
.phoneBottomBar .textButton .svg {
  display: none;
}
.phoneBottomBar .textButton .icon {
  display: none;
}
.phoneBottomBar .textButton span {
  top: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: white;
  line-height: 20px;
  display: block;
  position: relative;
  margin: 0 0 0 -15px;
  width: 70px;
  position: absolute;
}

.bg-bottom-wrap {
  display: grid;
  grid-template-columns: 1.2fr 2fr 0.6fr;
  /* background: rgb(246, 246, 246); */
  height: 60px;
  position: absolute;
  bottom: 5%;
  width: 90%;
  left: 5%;
  z-index: 3;
}
.bg-bottom-wrap > div {
  height: 100%;
}
.box-logo {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
}
.box-time {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.box-time .time {
  color: #747474;
  font-size: 16px;
}
.box-time .slogan {
  /* transform: scale(0.7); */
  color: #c6c6c6;
  font-size: 12px;
}
.box-qr {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.reader_form_phone .page {
  position: relative;
  margin-bottom: 20px;
}

.reader_form_phone .pageImage {
  /* max-width:98%; */
  height: auto;
  width: 100%;
  margin-left: 50%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  border: 1px solid #999;
}

.reader_form_phone .mask {
  position: absolute;
  z-index: 1;
  /* background-color:rgba(0,0,0,0.5); */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background: url(./mask.svg) no-repeat 100%/cover; */
}
