@import '../abstracts/variables';
@import '../abstracts/breckpoint';

.wrap_table {
  &.table_scroll {
    overflow-x: auto;
    @include md {
      overflow-x: unset;
      overflow-y: auto;
    }
    @include sm {
      height: calc(60vh);
    }
    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      @apply rounded-md bg-primary;
    }
  }
}

table {
  caption-side: bottom;
  font-size: 14px;
  overflow: hidden;
  thead tr th {
    white-space: nowrap;
    @apply whitespace-nowrap;
    &.wrap {
      white-space: inherit;
    }
  }
  &.dataTable {
    width: 100%;
    margin: 0 auto;
    clear: both;
    border-collapse: separate;
    border-spacing: 0;
    &.no-footer {
      @apply border-b-[1px] border-solid border-gray-200;
    }
    thead th,
    thead td {
      padding: 10px 18px;
      @apply border-b-[1px] border-solid border-gray-200;
      text-align: left;
    }
    thead th,
    tfoot th {
      font-weight: 700;
    }
  }
  &.dataTable,
  &.dataTable th {
    box-sizing: content-box;
  }

  &.tableBase {
    thead th,
    tbody td {
      padding-block: 14px;
    }
  }

  .theadPrimary {
    tr th {
      @apply text-white bg-primary;
    }
  }

  .theadRounded {
    tr th {
      &:first-child {
        border-top-left-radius: 8px;
        padding-left: 24px;
      }

      &:last-child {
        border-top-right-radius: 8px;
      }
    }
  }

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }
  tbody {
    background-color: white;
    &.tbody_even {
      tr:nth-child(even) {
        @apply bg-slate-200;
      }
    }

    &.tbody_border {
        tr:not(:last-child) td {
            @apply border-b-[1px] border-solid border-gray-200;

        }
    }

    th td {
      @apply border-t-[1px] border-r-[1px] border-solid border-gray-200;
    }
    tr {
      td {
        padding: 10px 18px;
        vertical-align: middle;
        @apply text-black-500;

        &:first-child {
            padding-left: 24px;
        }

        .quantity-control {
          @apply flex items-center justify-center;
          gap: 20px;
          .btn-minus,
          .btn-plus {
            cursor: pointer;
            @apply transition-all;

            &:hover {
              opacity: 0.8;
            }
          }
        }

        &.td_mw {
          min-width: 150px;
        }
        .td-img {
          width: 60px;
          overflow: hidden;
          @include lg {
            width: 55px;
          }
          @include md {
            margin-left: auto;
          }
        }

        .act-wrap {
            cursor: pointer;
            justify-content: flex-end;
            > div {
                @apply w-8 h-8 flex items-center justify-center rounded-full transition-all;
            }

            @include sm {
                justify-content: stretch;
            }
        }

        .act-edit {
            @apply text-blue-400 hover:bg-blue-50;

            a {
                color: unset;
            }
        }

        .act-delete {
            @apply text-red-400 hover:bg-red-50;
        }

        svg {
            width: 18px;
            height: 18px;
        }

        @include md {
          min-height: 50px;
        }
      }
    }
    tr td:first-child,
    tr th:first-child {
      @apply border-l-gray-200;
    }
  }
}

/* Responsive */

@media only screen and (min-width: 47.9375em) {
  table {
    tbody tr:last-child td {
      border: unset;
    }
  }
}

@media only screen and (max-width: 39.9375em) {
  .home-legal_documents_table {
    height: 70vh;
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.28) 0px -50px 36px -28px inset;
  }
  .dataTable thead {
    display: none;
  }

  table tbody tr td {
    .quantity-control {
      justify-content: flex-end;
    }
  }

  .dataTable,
  .dataTable tbody,
  .dataTable tr,
  .dataTable td {
    display: block;
    width: 100%;
  }
  .dataTable tbody tr {
    &:not(:last-child) {
      @apply border-b-[4px] border-solid border-gray-200;
    }
    td {
      text-align: right;
      position: relative;
      white-space: unset !important;
      padding-left: 30%;
      width: 100% !important;
      word-break: break-word;
      &:last-child .btn {
        margin-left: auto;
      }
      &::before {
        position: absolute;
        top: 50%;
        @apply -translate-y-1/2;
        content: attr(data-label);
        left: 0;
        width: 30%;
        padding-left: 15px;
        font-weight: 700;
        text-align: left;
        font-size: 13px;
        line-height: 1.2;
      }
    }
  }
}
