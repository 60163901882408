body {
  color: $darkMainColor;
}

button {
  svg {
    width: 16px;
    height: 16px;
  }
}

// screen
.backdrop-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  background-color: rgba(14, 18, 31, 0.95);
  animation: fadeIn linear 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
}
// end screen

.text-link {
  @apply text-sm text-blue-600 transition-all hover:underline;
}

.line1 {
  @apply border-b border-solid border-slate-100 pb-5 mb-5;
}
