@import '../abstracts/variables';
@import '../abstracts/breckpoint';

.homeBackground {
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  @include lg {
      background-size: 100% calc(100% - (var(--height-comp) - 4px));
    }
}
